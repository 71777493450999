/********************* Custom Animations *********************/ 

@keyframes ct_fadeInDown {
    0% {
        opacity: 0; 
        transform: translateY(-20px);
    }
    50% {
        opacity: 0; 
        transform: translateY(-20px);
    }
    100% {
        opacity: 1; 
        transform: translateY(0);
    }
}

@keyframes ct_fadeInUp {
    0% {
        opacity: 0; 
        transform: translateY(20px);
    }
    50% {
        opacity: 0; 
        transform: translateY(20px);
    }
    100% {
        opacity: 1; 
        transform: translateY(0);
    }
}

@keyframes movingright {
    0% {
        transform: translateX(0px); 
    }

    40% {
        transform: translateX(30px);
    }

    50% {
        transform: translateX(30px); 
        opacity: 0;
    }
    60% {
        transform: translateX(-30px); 
        opacity: 0;
    }
    100% {
        transform: translateX(0); 
        opacity: 1;
    }
}

@keyframes movingleft {
    0% {
        transform: tranlateX(-30px); 
    }
    100% {
        transform: tranlateX(0);
    }
}

@keyframes shine {
    from {
        left: -100px; 
    }
    to {
        left: 120%;
    }
}

@keyframes ripple_animation {
    0% {
      box-shadow: 0 0 0 0 $home6-heading-color; 
    }
    50% {
      box-shadow: 0 0 0 20px rgba(1, 93, 199, 0); 
    }
    100% {
      box-shadow: 0 0 0 0 rgba(1, 93, 199, 0); 
    } 
}

@keyframes ctLoadingdrop1 {
    100% {
      transform: translate(32px, 10px) scale(0);
    }
}

@keyframes loading-run {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%, 95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%, 59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }

  @keyframes loading-round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loading-round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes ctLoadingrotate {
    50% {
      transform: rotate(360deg);
      margin-left: 0;
    }
    50.0001%, 100% {
      margin-left: -70px;
    }
  }
  @keyframes ctLoadingmove {
    0%, 50% {
      left: -10px;
    }
    25% {
      background: #0431b8;
    }
    75% {
      background: #85cc02;
    }
    50.0001%, 100% {
      left: auto;
      right: -10px;
    }
  }
  @keyframes ctLoadingdrop1 {
    100% {
      transform: translate(32px, 10px) scale(0);
    }
  }