.header-style-7 {
    .header-navigation {
        padding-top: 20px;
    }
    &.header-sticky {
        .header-navigation {
            padding-top: 10px;
        }
        
    }
}
.slider-7-wrapper {
    position: relative;
    &::before {
        content: ''; 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 300px; 
        background-color: #000921;
    }
}
.hero-slider-7 {
    max-width: 1520px; 
    margin-left: auto; 
    margin-right: auto;
    margin-top: -90px;
    position: relative; 
    z-index: 1;
    overflow: hidden;
    &::after {
        content: ''; 
        position: absolute; 
        top: 0; 
        left: 50%;
        transform: translateX(-50%); 
        width: 5000px;
        background-color: $black-color;
        height: 300px;
        z-index: 1;
    }
    rs-module-wrap {
        position: relative; 
        z-index: 10;
    }
}
.home7-booking-form {
    .booking-form {
        background-repeat: no-repeat; 
        background-size: cover;
        border-radius: 3px;
        padding: 30px;
        .form-title {
            span {
                font-size: 24px; 
                color: $white-color; 
                font-weight: 700;
            }
        }
        form {
            width: 100%; 
            display: flex; 
            align-items: center;
            margin-top: 20px;
            input {
                width: 27%;
                padding: 16px 18px;
                border: 0; 
                margin-right: 15px;
                border-radius: 3px;
                font-size: 15px;
            }
            .select-field {
                width: 27%; 
                margin-right: 15px;
                select {
                    width: 100%;
                    padding: 16px 18px;
                    border: 0; 
                    border-radius: 3px; 
                    font-size: 15px; 
                    color: $paragraph-color;
                }
            }
            button {
                padding: 16px;
                width: 185px; 
                text-align: center;
                background-color: $home7-primary;
                font-family: $heading-font; 
                font-weight: 700;
                border: 0; 
                border-radius: 3px;
                font-size: 15px;
                position: relative; 
                z-index: 1;
                overflow: hidden;
                &::before {
                    content: ''; 
                    position: absolute; 
                    top: 0; 
                    left: -40px; 
                    width: 30px; 
                    height: 100%;
                    transform: skew(-10deg); 
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.438), rgba(255, 255, 255, 0.527));
                    z-index: -1;
                }
                &:hover {
                    &::before {
                        animation: 800ms shine; 
                    }
                }
            }
        }
    }
    @include medium-screen {
        .booking-form {
            form {
                flex-wrap: wrap;
                input {
                    width: 47%;
                    margin-bottom: 15px;
                }
                .select-field {
                    width: 47%;
                }
                button {
                    width: 47%;
                }
            }
        }
    }
    @include small-screen {
        .booking-form {
            form {
                input {
                    width: 100%;
                    margin-right: 0;
                }
                .select-field {
                    width: 100%;
                    margin-right: 0;
                }
                button {
                    width: 100%;
                    margin-top: 15px;
                }
            }
        }
    }
}

/*************** Home7 About *************/ 
.home7-about {
    .ab-left {
        position: relative; 
        .ex-year {
            position: absolute;
            right: -40px; 
            top: 40px;
            background-color: $white-color;
            width: 200px; 
            height: 150px;
            display: inline-block;
            padding-left: 20px;
            z-index: 3;
            &::before {
                content: ''; 
                position: absolute; 
                top: -10px; 
                left: -10px; 
                width: 170px; 
                height: 100%;
                background-color: transparent;
                z-index: -1;
                border-top: 10px solid $home7-primary;
                border-left: 10px solid $home7-primary;
            }
            .title {
                font-size: 90px; 
                font-family: $heading-font; 
                font-weight: 700;
                color: $home7-secondary;
                display: block;
                margin-top: -10px;
                position: relative;
                sup {
                    font-weight: 400;
                    color: $home7-primary;
                    font-size: 34px;
                    position: absolute;
                    top: 40px;
                }
            }
            .subtitle {
                font-size: 18px;
                font-weight: 700;
                margin-top: -20px;
                display: block;
                color: $black-color;
                text-transform: capitalize;
            }
        }
    }
    .about-right {
        padding-left: 40px;
        .about-list {
            margin-top: 40px;
            ul {
                li {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 700;
                    text-transform: capitalize;
                    color: $black-color;
                    + li {
                        margin-top: 10px;
                    }
                    span {
                        margin-right: 15px;
                        width: 20px;
                    }
                }
            }
        }
        .about-btn {
            margin-top: 40px;
            a {
                display: inline-flex; 
                align-items: center;
                background-color: $home7-secondary;
                padding: 14px 25px 10px 20px;
                border-radius: 50px;
                .icon {
                    color: $home7-primary;
                    font-size: 30px;
                }
                .tel-number {
                    margin-left: 10px;
                    .title {
                        display: block;
                        text-transform: uppercase; 
                        font-size: 15px;
                        color: $white-color;
                    }
                    .number {
                        color: $home7-primary;
                        font-size: 20px; 
                        font-weight: 500; 
                        font-family: $heading-font;
                    }
                }
            }
        }
    }
    @include medium-screen {
        .about-right {
            margin-top: 60px;
            padding-left: 0;
        }
    }
}

/*************** Home7 Services *************/ 
.home7-services-area {
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: bottom left;
    .home7-service-slider {
        margin-top: 60px;
        .single-item {
            .thumb-wrapper {
                position: relative; 
                &::before {
                    content: ''; 
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%; 
                    background-color: rgba(0,0,0,0.2);
                }
                .hover-overlay {
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%; 
                    background-color: rgba(0,0,0,0.8);
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    opacity: 0; 
                    visibility: hidden;
                    transition: $transition;
                    a {
                        background-color: $home7-primary;
                        font-size: 15px; 
                        font-weight: 700; 
                        color: #0a0a0a; 
                        display: inline-block; 
                        padding: 8px 18px;
                        border-radius: 30px;
                    }
                }
            }
            .icon-wrapper {
                margin-top: -55px;
                span {
                    width: 95px; 
                    height: 95px; 
                    background-color: #f0f0f0;
                    display: inline-flex; 
                    align-items: center; 
                    justify-content: center;
                    font-size: 40px; 
                    color: $home7-secondary;
                    position: relative; 
                    z-index: 1; 
                    &::before {
                        content: ''; 
                        position: absolute; 
                        width: 100%; 
                        height: 55px;
                        top: 0; 
                        left: 0;
                        background-color: transparent;
                        z-index: -1;
                        border-top: 6px solid $home7-secondary;
                        border-right: 6px solid $home7-secondary;
                        transition: $transition;
                    }
                }
            }
            .sr-content {
                .h7-pera-txt {
                    margin-top: 20px;
                }
            }
            &:hover {
                .thumb-wrapper {
                    .hover-overlay {
                        opacity: 1; 
                        visibility: visible;
                        a {
                            animation: 1s ct_fadeInUp;
                        }
                    }
                }
                .icon-wrapper {
                    span {
                        &::before {
                            border-color: $home7-primary;
                        }
                    }
                }
            }
        }
        .slick-list {
            margin: 0 -15px;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-dots {
            position: absolute; 
            bottom: -50px; 
            left: 50%; 
            transform: translateX(-50%);
            li {
                display: inline-block;
                button {
                    width: 35px; 
                    height: 3px; 
                    background-color: #b4b6c0;
                    font-size: 0; 
                    padding: 0;
                    border: 0;
                    margin-right: 6px;
                    transition: $transition;
                }
                &.slick-active {
                    button {
                        background-color: $home7-secondary;
                    }
                }
            }
        }
        @include small-screen {
            max-width: 500px; 
            margin-left: auto; 
            margin-right: auto;
            .single-item {
                .sr-content {
                    text-align: center;
                }
            }
        }
    }
}

/*************** Home7 Team Area **************/ 
.home7-team-area {
    .home7-team-bottom {
        margin-top: 60px;
        .team-column {
            text-align: center;
            border: 2px solid $border-color;
            transition: $transition;
            position: relative;
            margin-bottom: 50px;
            .img-wrapper {
                position: relative;
                .hover-content {
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%; 
                    background-color: rgba(0,0,0,0.8); 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    flex-direction: column;
                    opacity: 0; 
                    visibility: hidden;
                    transition: $transition;
                    padding: 0 20px;
                    p {
                        color: $white-color;
                        font-size: 15px;
                    }
                    a {
                        margin-top: 20px;
                        padding: 8px 18px; 
                        background-color: $home7-primary;
                        border-radius: 30px; 
                        font-size: 15px; 
                        font-weight: 700;
                        color: #0a0a0a;
                    }
                }
            }
            .team-content {
                padding: 20px 0 40px 0;
                span {
                    font-size: 15px;
                }
            }
            .team-social {
                position: absolute;
                left: 50%; 
                bottom: -15px; 
                transform: translateX(-50%);
                display: inline-block; 
                background-color: $white-color;
                a {
                    width: 40px; 
                    height: 40px; 
                    display: inline-block; 
                    text-align: center; 
                    line-height: 40px;
                    border: 1px solid $border-color;
                    background-color: $white-color;
                    color: #b7b7b7;
                    transition: $transition;
                    + a {
                        margin-left: -3px;
                        border-left: 0;
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $home7-secondary;
                    }
                }
            }
            &:hover {
                border: 2px solid $home7-secondary;
                .img-wrapper {
                    .hover-content {
                        opacity: 1; 
                        visibility: visible; 
                        p {
                            animation: 800ms ct_fadeInUp;
                        }
                        a {
                            animation: 1.3s ct_fadeInUp; 
                        }
                    }
                }
            }
            @include portrait-phone {
                max-width: 400px;
                margin-left: auto; 
                margin-right: auto;
            }
        }
    }
}


/************* Home7 Case Studies ****************/ 
.home7-case-studies {
    background-color: #f0f0f0;
    .view-all-btn {
        text-align: right;
        a {
            padding: 10px 15px 10px 40px;
            background-color: $home7-secondary;
            color: $white-color; 
            border-radius: 40px;
            font-size: 15px; 
            font-weight: 700;
            display: inline-flex; 
            align-items: center;
            position: relative;
            overflow: hidden;
            &::before {
                content: ''; 
                position: absolute; 
                top: 0; 
                left: 0; 
                width: 30px; 
                height: 100%; 
                z-index: 1;
                left: -40px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0.384), rgba(255, 255, 255, 0.39)); 
                transform: skew(-10deg);
            }
            span {
                margin-left: 20px;
                width: 30px; 
                height: 30px;
                background-color: $white-color; 
                border-radius: 50%;
                color: $home7-secondary;
                display: inline-flex; 
                align-items: center; 
                justify-content: center;
            }
            &:hover {
                &::before {
                    animation: 900ms shine; 
                }
            }
        }
    }
    .studies-content-wrapper {
        margin-top: 50px;
        .studies-column {
            position: relative;
            border-radius: 3px; 
            overflow: hidden;
            margin-bottom: 25px;
            .studies-content {
                position: absolute; 
                top: 0; 
                left: 0; 
                width: 100%; 
                height: 100%; 
                background-color: rgba(0,0,0,0.8); 
                display: flex; 
                flex-direction: column; 
                justify-content: flex-end;
                padding: 30px;
                opacity: 0; 
                visibility: hidden;
                transition: $transition;
                h6 {
                    color: $white-color;
                }
                span {
                    color: $home7-primary;
                    font-size: 15px;
                    margin-top: 3px;
                    display: inline-block;
                }
            }
            &:hover {
                .studies-content {
                    opacity: 1; 
                    visibility: visible;
                    h6 {
                        animation: 800ms ct_fadeInUp; 
                    }
                    span {
                        animation: 1s ct_fadeInUp;
                    }
                }
            }
        }
    }
    @include medium-screen {
        .view-all-btn {
            text-align: left;
            margin-top: 20px;
        }
    }
}

/************ Home7 Pricing Plan ************/ 
.home7-pricing-plan {
    .price-filter-btns {
        text-align: right;
        .nav {
            border-bottom: 0;
            display: inline-block; 
            background-color: $home7-secondary;
            padding: 6px 8px; 
            border-radius: 30px;
            li {
                display: inline-block;
                + li {
                    a {
                        margin-left: -10px;
                    }
                }
                a {
                    color: $white-color; 
                    font-family: $heading-font; 
                    font-weight: 600;
                    font-size: 15px; 
                    font-weight: 600;
                    padding: 6px 15px;
                    display: inline-block; 
                    border-radius: 30px;
                    &.active {
                        background-color: $home7-primary;
                        color: $black-color;
                        
                    }
                }
            }
        }
    }
    .tab-content {
        margin-top: 80px;
        padding: 0 50px;
        .price-column {
            background-color: #f6f8fa;
            padding: 25px;
            min-height: 390px;
            border-radius: 5px;
            position: relative;
            margin-bottom: 40px;
            &.pro {
                transform: translateY(-15px);
                .price-wrapper {
                    &::before {
                        border-right: 11px solid #6e5708;
                    }
                    .price-tag {
                        background-color: #ffc80f;
                        .title {
                            color: $black-color;
                        }
                        .subtitle {
                            color: #6e6e6e;
                        }
                    }
                }
            }
            &.premium {
                transform: translateY(15px);
            }
            .price-wrapper {
                position: absolute;
                right: 20px; 
                top: -25px;
                text-align: center;
                padding-top: 12px;
                &::before {
                    content: ''; 
                    position: absolute; 
                    top: 13px; 
                    left: -22px;
                    z-index: 10; 
                    width: 0; 
                    height: 0; 
                    border-top: 11px solid transparent; 
                    border-left: 11px solid transparent; 
                    border-right: 11px solid #0a1e6a;
                }
                .price-tag {
                    width: 130px; 
                    height: 140px; 
                    background-color: #5675e8;
                    clip-path: polygon(0 0, 100% 0, 100% 52%, 50% 100%, 50% 100%, 0 52%);
                    padding-top: 15px;
                    span {
                        color: $white-color;
                        &.title {
                            font-size: 40px; 
                            font-family: $heading-font; 
                            font-weight: 700;
                        }
                        &.subtitle {
                            font-size: 20px;
                            display: inline-block;
                            transform: translateY(-12px);
                            font-weight: 500;
                            color: #c7cee7;
                        }
                    }
                }
            }
            .icon-wrapper {
                span {
                    display: inline-block;
                }
            }
            .title {
                span {
                    font-family: $heading-font; 
                    font-weight: 600;
                    font-size: 38px;
                    color: $black-color;
                }
            }
            .feature-list {
                margin-top: 20px;
                margin-bottom: 60px;
                ul {
                    li {
                        display: flex;
                        + li {
                            margin-top: 15px;
                        }
                        span {
                            display: inline-block;
                            transform: translateY(-6px);
                            img {
                                width: 20px;
                            }
                        }
                        p {
                            margin-left: 6px;
                            color: $black-color;
                            font-size: 18px;
                        }
                    }
                }
            }
            .price-btn {
                text-align: center;
                position: absolute; 
                bottom: 25px; 
                left: 50%; 
                transform: translateX(-50%);
                a {
                    font-family: $heading-font; 
                    font-weight: 700;
                    color: $black-color;
                    font-size: 15px;
                    &:hover {
                        color: $home7-secondary;
                    }
                }
            }
        }
    }
    @include medium-screen {
        .price-filter-btns {
            text-align: left;
            margin-top: 30px;
        }
    }
    @include small-screen {
        .title-style-5 {
            text-align: center;
        }
        .price-filter-btns {
            text-align: center;
        }
        .tab-content {
            padding: 0;
            .price-column {
                max-width: 450px;
                margin-left: auto; 
                margin-right: auto;
                &.pro {
                    transform: initial;
                }
                &.premium {
                    transform: initial;
                }
            }
        }
    }
}

/*********** Home7 Feedback Area *************/ 
.home7-feedback-area {
    background-color: #00061e;
    .feedback-left {
        padding-right: 60px;
        .title-style-5 {
            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
                a {
                    color: $home7-primary;
                }
            }
        }
        .middle-content {
            margin-top: 20px;
            p {
                color: $home7-primary;
                span {
                    color: $white-color;
                }
                &:nth-child(2) {
                    color: $white-color;
                }
            }
        }
        .contact-form {
            margin-top: 30px;
            form {
                .field-double {
                    display: flex;
                    input {
                        width: 50%;
                        padding: 12px 18px; 
                        border: 0;
                        border-radius: 3px;
                        background-color: #1a1f35;
                        color: #dfdfdf; 
                        font-size: 15px;
                        &::placeholder {
                            color: #dfdfdf;
                        }
                        + input {
                            margin-left: 15px;
                        }
                    }
                }
                .select-field {
                    margin-top: 15px;
                    select {
                        width: 100%;
                        padding: 12px 18px; 
                        border: 0; 
                        border-radius: 3px; 
                        background-color: #1a1f35; 
                        color: #dfdfdf;
                        font-size: 15px;
                    }
                }
                .message {
                    margin-top: 15px;
                    textarea {
                        width: 100%; 
                        padding: 12px 18px; 
                        background-color: #1a1f35; 
                        color: #dfdfdf; 
                        border: 0; 
                        border-radius: 3px;
                        &::placeholder {
                            color: #dfdfdf;
                        }
                    }
                }
                .submit-btn {
                    margin-top: 30px;
                    button {
                        padding: 10px 10px 10px 20px;
                        border: 0; 
                        border-radius: 30px;
                        background-color: #0022a0;
                        color: $white-color;
                        font-size: 14px;
                        font-family: $heading-font; 
                        font-weight: 700;
                        display: inline-flex;
                        align-items: center;
                        position: relative; 
                        overflow: hidden;
                        &::before {
                            content: ''; 
                            position: absolute; 
                            top: 0; 
                            left: -40px; 
                            width: 30px; 
                            height: 100%; 
                            background-image: linear-gradient(to right, rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.267));
                            transform: skew(-10deg);
                        }
                        span {
                            margin-left: 12px;
                            width: 25px; 
                            height: 25px;
                            background-color: #ffc80f;
                            display: inline-block;
                            border-radius: 50%;
                            line-height: 25px;
                            text-align: center;
                            color: $black-color;
                        }
                        &:hover {
                            &::before {
                                animation: 800ms shine;
                            }
                        }
                    }
                }
            }
        }
    }
    .feedback-right {
        .title-style-5 {
            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
        }
        .home7-feedback-slider {
            margin-top: 60px;
            .feedback-column {
                position: relative;
                background-color: #1a1f35;
                padding: 40px 20px 50px 20px;
                &::after {  
                    content: '';
                    position: absolute; 
                    top: -1px; 
                    right: -1px;
                    width: 60px; 
                    height: 60px;
                    background-color: #00061e;
                    z-index: 2;
                    clip-path: polygon(0 0, 100% 100%, 100% 0);
                }
                .quote-icon {
                    position: absolute;
                    top: -65px; 
                    left: 20px;
                    display: inline-block;
                    font-family: 'Playfair Display', serif;
                    font-size: 160px;
                    font-weight: bold;
                    line-height: 0;
                    letter-spacing: -13px;
                    color: #394267;
                    transition: $transition;
                }
                .feedback-content {
                    p {
                        color: #e4e4e4;
                    }
                }
                .feedback-rating {
                    text-align: right;
                    span {
                        color: #ffc601;
                    }
                }
                .feedback-client-info {
                    display: flex;
                    align-items: flex-end;
                    gap: 20px;
                    position: absolute;
                    bottom: -60px;
                    left: 40px;
                    .client-thumb {
                        width: 95px; 
                        height: 95px;
                        border-radius: 50%;
                        position: relative;
                        img {
                            border-radius: 50%;
                            background-color: $white-color; 
                            padding: 4px;
                            position: relative; 
                            z-index: 2;
                        }
                        .svg-1 {
                            fill: #b3a987;
                            position: absolute;
                            top: -10px;
                            transition: $transition;
                        }
                        .svg-2 {
                            fill: #3f4e64; 
                            position: absolute;
                            bottom: -10px;
                            transition: $transition;
                        }
                    }
                    .clients-name {
                        padding-bottom: 5px;
                        h6 {
                            color: $white-color; 
                            font-weight: 700;
                            font-family: $heading-font;
                        }
                        span {
                            color: #4369f7;
                            font-size: 14px;
                        }
                    }
                }
                &:hover {
                    .quote-icon {
                        color: #ffc80f;
                    }
                    .feedback-client-info {
                        .client-thumb {
                            .svg-1 {
                                fill: #ffc80f;
                            }
                            .svg-2 {
                                fill: #0022a0;
                            }
                        }
                    }
    
                }
            }
            .slick-list {
                padding: 30px 0 100px 0;
                margin: 0 -15px;
                .slick-slide {
                    margin: 0 15px;
                }
            }
            .slick-dots {
                position: absolute;
                bottom: -60px;
                left: 50%;
                transform: translateX(-50%);
                li {
                    display: inline-block;
                    margin-right: 10px;
                    button {
                        width: 35px; 
                        height: 3px; 
                        padding: 0; 
                        border: 0;
                        background-color: #0022a0;
                        font-size: 0;
                        transition: $transition;
                    }
                    &.slick-active {
                        button {
                            background-color: $home7-primary;
                        }
                    }
                }
            }
            @include small-screen {
                max-width: 400px; 
                margin-left: auto; 
                margin-right: auto;
            }
        }
    }
    @include medium-screen {
        .feedback-right {
            margin-top: 60px;
        }
    }
    @include small-screen {
        .feedback-right {
            .home7-feedback-slider {
                .slick-dots {
                    li {
                        button {
                            width: 10px; 
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}

/************* Home7 Brands ***************/ 
.home7-brands {
    .brand-wrapper {
        .img-wrapper {
            img {
                filter: grayscale(0);
            }
        }
    }
}