/*********** Hero Slider **************/ 
.hero-slider-1 {
    margin-top: -30px;
    @include large-screen {
        margin-top: 0;
    }
}

/************ Services Section ***************/ 
.bixol-services {
    .bixol-service-wrapper {
        .bixol-single-item {
            position: relative;
            padding: 25px;
            padding-left: 35px;
            &.sr-item {
                margin-bottom: 100px;
                @include small-screen {
                    max-width: 400px; 
                    margin-left: auto; 
                    margin-right: auto;
                }
            }
            .bixol-icon-wrapper {
                width: 90px; 
                height: 90px; 
                border-radius: 50%;
                display: inline-flex; 
                align-items: center; 
                justify-content: center;
                padding: 20px;
                box-shadow: $box-shadow;
            }
            .bixol-sr-content {
                margin-top: 25px;
                h6 {
                    position: relative;
                    transition: $transition;
                    display: inline-block;
                    &::after {
                        content: ''; 
                        position: absolute; 
                        bottom: -5px; 
                        left: 0; 
                        width: 40px; 
                        height: 3px;
                        background-color: $secondary-color;
                    }
                }
                p {
                    margin-top: 20px;
                }
                a {
                    margin-top: 20px;
                }
            }
            .bixol-sr-hover {
                position: absolute; 
                top: -110px; 
                left: 0; 
                width: 100%;
                background-color: #dee5eb;
                z-index: 1;
                opacity: 0; 
                visibility: hidden;
                transition: $transition;
                .img-wrapper {
                    position: relative;
                    overflow: hidden;
                    img {
                        transition: $transition;
                    }
                    .img-shadow {
                        position: absolute; 
                        width: 130%; 
                        height: 120%; 
                        background-image: url(../../images/services/shadow.png);
                        bottom: -60px; 
                        left: -80px;
                        z-index: 1;
                        background-size: cover;

                    }
                    &::after {
                        content: ''; 
                         position: absolute; 
                        bottom: 6px; 
                        left: 50%;
                        transform: translateX(-50%); 
                        width: 110%;
                        height: 80px; 
                        clip-path: polygon(50% 0%, 0 100%, 100% 100%);
                        background-color: $white-color;
                        z-index: 1;
                    }
                    &::before {
                        content: ''; 
                        position: absolute;
                        bottom: -2px; 
                        left: 50%; 
                        transform: translateX(-50%);
                        width: 110%;
                        height: 80px;
                        clip-path: polygon(50% 0%, 0 100%, 100% 100%);
                        background-color: #dee5eb;
                        z-index: 2;
                    }
                }
                .icon-wrapper {
                    width: 90px; 
                    height: 90px; 
                    border-radius: 50%; 
                    box-shadow: $box-shadow;
                    display: block; 
                    margin-left: auto; 
                    margin-right: auto;
                    margin-top: -120px;
                    position: relative; 
                    z-index: 15;
                    .bixol-img {
                        border-radius: 50%;
                        background-color: $white-color; 
                        padding: 20px;
                        position: relative; 
                        z-index: 2;
                    }
                    .bixol-icon-shadow {
                        position: absolute; 
                        display: inline-block;
                        bottom: -6px; 
                        left: 0; 
                        width: 100%; 
                        height: 100%; 
                        border-radius: 50%; 
                        background-color: $primary-color;
                        z-index: -1;
                        
                    }
                }
                .bixol-sr-content {
                    padding: 25px;
                }
            }
            &:hover {
                &::after {
                    opacity: 0;
                }
                .bixol-sr-hover {
                    opacity: 1; 
                    visibility: visible;
                    .icon-wrapper {
                        animation: 0.5s ct_fadeInDown forwards;
                    }
                    .bixol-sr-content {
                        h6 {
                            animation: 0.3s ct_fadeInUp forwards;
                        }
                        p {
                            animation: 0.6s ct_fadeInUp forwards;
                        }
                        a.bixol-readmore-btn {
                            animation: 1s ct_fadeInUp forwards;
                        }
                    }
                }
            }
            &.slick-active {
                &::after {
                    content: ''; 
                    position: absolute; 
                    top: 50%; 
                    left: -20px; 
                    transform: translateY(-50%); 
                    width: 1px;
                    height: 50%;
                    border: 1px dashed $border-color;
                    transition: $transition;
                }
            }
        }
        .slick-list {
            padding: 100px 0px 80px 0;
        }
        .slick-arrow {
            position: absolute; 
            top: 50%; 
            left: 0px; 
            transform: translateY(-50%);
            border: none; 
            background-color: $border-color;
            width: 40px; 
            height: 40px; 
            text-align: center; 
            line-height: 40px; 
            border-radius: 50%; 
            color: $heading-color;
            transition: $transition; 
            opacity: 0;
            &:hover {
                background-color: $heading-color; 
                color: $white-color;
            }
            &.next-arrow {
                left: auto; 
                right: 0px;
            }
        }
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
            &.prev-arrow {
                left: -80px;
                @include small-screen {
                    left: -40px;
                }
            }
            &.next-arrow {
                right: -40px;
            }
        }
    }
    @include small-screen {
       .bixol-service-slider {
           max-width: 400px;
           display: block; 
           margin: 0 auto;
           .bixol-single-item {
               text-align: center;
               .bixol-sr-content {
                   h6 {
                       &::after {
                           left: 50%;
                           transform: translateX(-50%);
                       }
                   }
               }
           }
       }
    }
}

/********** About Section ****************/ 
.bixol-about-section {
    position: relative;
    .bixol-about-bg {
        position: absolute; 
        top: 50%; 
        left: 0; 
        transform: translateY(-50%);
        width: 50%;
        z-index: -1;
    }
    .bixol-about-left {
        text-align: right;
        .bixol-slider-wrapper {
            display: inline-block;
            position: relative; 
            &::before {
                content: ''; 
                position: absolute; 
                top: 50%;  
                right: 0px;
                transform: translateY(-50%); 
                background-color: $primary-color;
                width: calc(100% + 30px); 
                height: calc(100% + 20px);
                border-radius: 50%;
                z-index: 1;
            }
            &::after {
                content: ''; 
                position: absolute; 
                top: 50%; 
                right: 0; 
                transform: translateY(-50%); 
                width: calc(100% + 5px); 
                height: calc(100% + 0px);
                background-color: $white-color;
                border-radius: 50%;
                z-index: 1;
            }
            .bixol-slider-content {
                position: absolute; 
                background-color: $primary-color;
                color: $white-color;
                top: -85px;
                right: 0;
                width: 105px; 
                height: 105px; 
                border-radius: 50%;
                display: flex; 
                align-items: center;
                flex-direction: column;
                justify-content: center;
                z-index: 15;
                &::after {
                    content: ''; 
                    position: absolute; 
                    bottom: -3px; 
                    left: 15px;
                    width: 0; 
                    height: 0; 
                    border-left: 10px solid transparent; 
                    border-right: 10px solid transparent; 
                    border-top: 15px solid $primary-color;
                    transform: skew(-30deg);
                }
                .icon-wrapper {
                    i {
                        color: $secondary-color;
                        font-size: 30px;
                        line-height: 0; 
                        display: inline-block;
                    }
                }
                .content-bottom {
                    text-align: center;
                    span {
                        font-size: 12px;
                    }
                    h6 {
                        color: $white-color;
    
                    }
                }
            }
        }
        .bixol-compare-slider {
            width: 285px; 
            height: 285px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            position: relative;
            z-index: 10;
        }
    }
    .bixol-about-right {
        padding-left: 65px;
        .devider {
            display: block; 
            margin: 30px 0;
            hr {
                border-top: 2px solid $border-color;
                opacity: 0.50;
                margin: 0;
            }
        }
        .bixol-about-content {
            display: flex;
            gap: 20px;
            align-items: center;
            .video-wrapper {
                .video-thumb {
                    position: relative; 
                    border-radius: 3px; 
                    overflow: hidden;
                    a {
                        position: absolute; 
                        top: 50%; 
                        left: 50%; 
                        transform: translate(-50%, -50%);
                        width: 40px; 
                        height: 40px; 
                        background-color: rgba(4, 49, 184, 0.7);
                        border-radius: 50%;
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        color: $white-color;
                        text-decoration: none;
                        border: 2px solid $white-color;
                    }
                }                
            }
            .video-content {
                .title {
                    font-family: $heading-font; 
                    font-weight: 700;
                    color: $heading-color;
                    margin-bottom: 6px;
                    display: block;
                }
                p {
                    font-size: 15px;
                }
            }
            @include portrait-phone {
                display: block;
                text-align: center; 
                .video-wrapper {
                    .video-thumb {
                        img {
                            max-width: 100%;
                            width: auto;
                            display: inline-block;
                        }
                    }
                }
                .video-content {
                    margin-top: 20px; 
                    text-align: center;
                }
            }
        }
    }
    @include medium-screen {
        padding-top: 100px;
        .bixol-about-bg {
            display: none;
        }
        .bixol-about-left {
            text-align: center;
        }
        .bixol-about-right {
            padding-left: 0;
            margin-top: 60px;
            max-width: 600px; 
            text-align: center;
            display: block; 
            margin-left: auto; 
            margin-right: auto;
            .bixol-about-content {
                .video-content {
                    text-align: left;
                }
            }
        }
    }
    @include portrait-phone {
        .bixol-about-right {
            .bixol-about-content {
                display: block;
                text-align: center; 
                .video-wrapper {
                    .video-thumb {
                        img {
                            max-width: 100%;
                            width: auto;
                            display: inline-block;
                        }
                    }
                }
                .video-content {
                    margin-top: 20px; 
                    text-align: center;
                }
            }
        }
        
    }
}

/************* Service Section **********************/ 
.bixol-service-section {
    .bixol-services {
        .bixol-service-item {
            display: flex;
            align-items: center;
            gap: 20px;
            transition: $transition;
            margin-bottom: 30px;
            .bixol-icon-wrapper {
                span {
                    width: 80px; 
                    height: 80px; 
                    color: $white-color;
                    border-radius: 50%;
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    position: relative; 
                    font-size: 30px;
                    transition: $transition;
                    &::before {
                        content: ''; 
                        position: absolute; 
                        left: -5px; 
                        bottom: -5px;
                        width: 100%; 
                        height: 100%; 
                        border-radius: 50%; 
                        background-color: $secondary-color; 
                        z-index: 2;
                    }
                    i {
                        background-color: $heading-color;
                        height: 100%; 
                        width: 100%;
                        border-radius: 50%;
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        position: relative; 
                        z-index: 2;
                        transition: $transition;
                    }
                }
            }
            .bixol-service-content {
                background-color: $white-color;
                box-shadow: $box-shadow;
                padding: 35px 35px 35px 50px;
                margin-left: -50px;
                position: relative; 
                z-index: 1;
                &::before {
                    content: ''; 
                    position: absolute;
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%;
                    background-image: url(../../images/home1/sr-item-bg.jpg);
                    background-repeat: no-repeat; 
                    background-size: cover;
                    z-index: -1;
                    opacity: 0;
                    transition: $transition;
                }
                h5 {
                    margin-bottom: 6px;
                }
            }
            &:hover {
                transform: translateY(-10px);
                .bixol-icon-wrapper {
                    span {
                        i {
                            background-color: $white-color;
                            color: $heading-color;
                        }
                    }
                }
                .bixol-service-content {
                    &::before {
                        opacity: 1;
                    }
                    h5 {
                        color: $white-color; 
                    }
                    p {
                        color: $white-color;
                    }
                }
            }
        }
    }
    .bixol-service-middle {
        .img-wrapper {
            position: relative; 
            .banner-content {
                width: 120px;
                height: 120px;
                display: flex; 
                align-items: center; 
                justify-content: center;
                flex-direction: column;
                border-radius: 10px;
                position: absolute; 
                top: -30px; 
                left: 50px;
                text-align: center;
                h3 {
                    color: $white-color;
                    margin-bottom: 5px;
                    position: relative; 
                    z-index: 10;
                }
                p {
                    color: $white-color;
                    font-size: 13px;
                    line-height: 1;
                    position: relative; 
                    z-index: 10;
                }
                .banner-svg-1 {
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%;
                    background-color: $primary-color;
                    clip-path: polygon(0 0, 100% 9%, 100% 95%, 0% 100%);
                    z-index: 2;
                    border-radius: 15px 25px 25px 15px;
                }
                .banner-svg-2 {
                    position: absolute; 
                    top: 8px; 
                    left: -8px; 
                    width: 100%; 
                    height: 100%;
                    background-color: $secondary-color;
                    clip-path: polygon(0 0, 100% 9%, 100% 95%, 0% 100%);
                    z-index: 1;
                    border-radius: 15px 25px 25px 15px;
                }
            }
        }
    }
    @include medium-screen {
        .bixol-services {
            .bixol-service-item {
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .bixol-service-middle {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
            .img-wrapper {
                width: auto; 
                max-width: 100%;
                display: inline-block;
            }
        }
    }
}


/******************** Counterup Section ***********************/ 
.bixol-ct-section {
    .bixol-counter-column {
        display: flex;
        align-items: center; 
        gap: 15px;
        position: relative;
        margin-bottom: 30px;
        .bixol-icon-wrapper {
            span {
                font-size: 48px;
                display: inline-block;
            }
        }
        .bixol-ct-content {
            h3 {
                sup {
                    color: $secondary-color;
                    font-weight: 700;
                    font-family: $heading-font;
                }
            }
            .subtitle {
                font-weight: 500;
            }
        }
        &.bx-col-line {
            &::after {
                content: ''; 
                position: absolute; 
                top: 50%; 
                right: 15px; 
                transform: translateY(-50%); 
                width: 0; 
                height: 90%;
                border: 1px dashed $border-color;
            }
        }
    }
    @include medium-screen {
        .bixol-counter-column {
            &.bx-col-line {
                &::after {
                    display: none;
                }
            }
        }
    }
    @include portrait-phone {
        .bixol-counter-column {
            display: block;
            text-align: center;
        }
    }
}

/****************************** Team Section ***********************/ 
.bixol-team-area {
    background-color: #e3e8ed;
    .bixol-title-area {
        padding-left: 60px;
    }
    .bixol-team-container {
        .bixol-team-single {
            background-color: $white-color;
            transition: $transition;
            overflow: hidden;
            margin-bottom: 50px;
            .bixol-img-wrapper {
                position: relative; 
                &::before {
                    content: ''; 
                    position: absolute; 
                    bottom: 0; 
                    left: 0; 
                    width: calc(100% + 20px); 
                    height: 40px;
                    clip-path: polygon(0 0, 100% 70%, 100% 100%, 0% 100%);
                    background-color: $secondary-color;
                }
                &::after {
                    content: ''; 
                    position: absolute; 
                    bottom: -10px; 
                    left: 0; 
                    width: calc(100% + 20px); 
                    height: 40px;
                    clip-path: polygon(0 0, 100% 70%, 100% 100%, 0% 100%);
                    background-color: $white-color;
                }
            }
            .bixol-team-content {
                padding: 20px;
                position: relative; 
                .team-social-link {
                    position: absolute; 
                    right: 20px;
                    bottom: 20px;
                    width: 50px; 
                    height: 50px;
                    background-color: $primary-color;
                    color: $white-color; 
                    text-align: center; 
                    line-height: 50px;
                    border-radius: 0 0 25px 25px;
                    transition: $transition;
                }
                .bixol-team-social-items {
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    bottom: 90px;
                    border-radius: 25px 25px 0 0;
                    overflow: hidden;
                    opacity: 0; 
                    visibility: hidden;
                    transition: $transition;
                    a {
                        color: $white-color;
                        width: 50px; 
                        height: 50px; 
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        background-color: $primary-color;
                        transition: $transition;
                        &:hover {
                            padding-left: 10px; 
                        }
                    }
                }
            }
            &:hover {
                transform: translateY(-15px);
                box-shadow: $light-shadow;
                .bixol-team-content {
                    .team-social-link {
                        background-color: $white-color; 
                        color: $secondary-color;
                    }
                    .bixol-team-social-items {
                        bottom: 70px;
                        opacity: 1; 
                        visibility: visible;
                    }
                }
            }
        }
        .slick-list {
            padding: 60px 0;
            margin: 0 -15px;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-dots {
            position: absolute; 
            bottom: -20px;
            left: 50%; 
            transform: translateX(-50%);
            li {
                display: inline-block;
                width: 20px; 
                height: 20px; 
                border-radius: 50%; 
                border: 1px solid transparent;
                padding: 3px;
                text-align: center; 
                line-height: 20px;
                transition: $transition;
                button {
                    font-size: 0;
                    display: block;
                    width: 100%; 
                    height: 100%;
                    border: 0; 
                    padding: 0;
                    border-radius: 50%;
                    background-color: #8b90a7;
                    transition: $transition;
                }
                &.slick-active {
                    border: 2px solid $primary-color;
                    button {
                        background-color: $primary-color;
                    }
                }
            }
        }

    }
    @include portrait-phone {
        .bixol-title-area {
            text-align: center;
            padding-left: 0;
        }
        .bixol-team-container {
            max-width: 300px;
            margin-left: auto; 
            margin-right: auto;
        }
    }
}


/****************************** Case Study ***************************/ 
.bixol-case-study {
    .bixol-ct-left {
        display: flex; 
        align-items: center; 
        justify-content: center;
        flex-direction: column;
        background-repeat: no-repeat;
        padding: 10px 0 40px 0;
        .ct-title {
            font-family: $heading-font; 
            font-weight: 700; 
            font-size: 150px;
            sup {
                color: $primary-color;
            }
        }
        .ct-subtitle {
            color: $heading-color; 
            font-size: 22px;
            display: inline-block;
            margin-top: -35px;
        }
    }
    .bixol-ct-right {
        display: flex;
        flex-wrap: wrap;
        gap: 80px;
        .bixol-pt-item {
            padding: 60px 35px;
            img {
                transition: $transition;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }  
        .grid-item {
            border-right: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            &:nth-child(3) {
                border-right: 0;
            }
            &:nth-child(4), 
            &:nth-child(5),
            &:nth-child(6) {
                border-bottom: 0;
            }
            &:nth-child(6) {
                border-right: 0;
            }
        }
    }
    @include medium-screen {
        .bixol-ct-right {
            .bixol-pt-item {
                text-align: center;
                img {
                    max-width: 100%;
                    width: auto;
                    display: inline-block;
                }
            }
            .grid-item {
                &:nth-child(3) {
                    border-right: 1px solid $border-color;
                }
                &:nth-child(4) {
                    border-bottom: 1px solid $border-color;
                }
            }
        }
    }
    @include portrait-phone {
        .bixol-ct-right {
            .grid-item {
                border-bottom: 0 !important; 
                border-right: 0 !important;
               + .grid-item {
                   border-top: 1px solid $border-color;
               }
            }
        }
    }
}

/************************* Get In Tauch ************************/ 
.bixol-gta-area {
    position: relative;
    overflow: hidden;
    background-size: cover; 
    background-repeat: no-repeat;
    padding: 100px 0;
    background-size: 120%;
    background-position: -400px center;
    .bixol-help-desk-content {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .bixol-gt-left {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        z-index: 10;
        &::before {
            content: ''; 
            position: absolute;
            width: 170px;
            height: 170px;
            border-radius: 50%;
            background-color: #e0e2e8;
            z-index: -1;
            top: 50%; 
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .bixol-help-desk {
            width: 140px; 
            height: 140px;
            background-color: $white-color;
            border-radius: 50%;
            display: flex; 
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-shadow: $box-shadow;
            .desk-subtitle {
                font-size: 12px;
                color: #3465f8;
            }
            .desk-title {
                font-size: 24px;
                color: $heading-color;
                font-family: $heading-font; 
                font-weight: 700;
                margin-top: -3px;
            }
            .desk-call {
                font-size: 36px;
                line-height: 30px;
                font-weight: 700; 
                color: $secondary-color;
                margin-top: -3px;
            }

        }
    }
    .bixol-gt-right {
        padding-right: 60px;
        position: relative;
        max-width: 600px;
        h4 {
            margin-bottom: 20px;
            color: $white-color;
            text-transform: initial;
            position: relative; 
            display: inline-block;
            &::after {
                content: ''; 
                position: absolute; 
                right: -80px; 
                top: 50%; 
                transform: translateY(-50%); 
                width: 60px; 
                height: 2px;
                background-color: $secondary-color;
            }
        }
        form {
            width: 100%;
            input[type="text"] {
                width: 48%;
                border: 0;
                padding: 12px 20px;
                background-color: #1a386d;
                &::placeholder {
                    color: #c3c4e1;
                }
            }
            input[type="tel"] {
                width: 48%;
                border: 0; 
                padding: 12px 20px;
                background-color: #1a386d;
                font-size: 15px;
                float: right;
                &::placeholder {
                    color: #c3c4e1;
                }
            }
            input[type="email"] {
                width: 100%;
                border: 0; 
                padding: 12px 20px;
                background-color: #1a386d;
                font-size: 15px;
                float: right;
                margin-top: 20px;
                margin-bottom: 20px;
                &::placeholder {
                    color: #c3c4e1;
                }
            }
            .bx-form-select {
                width: 100%; 
                margin-top: 20px;
                select {
                    width: 100%;
                    padding: 12px 20px;
                    background-color: #1a386d; 
                    border: 0;
                    color: #c3c4e1;
                    font-size: 15px;
                }
            }
            textarea {
                width: 100%;
                margin-top: 20px;
                border: 0; 
                background-color: #1a386d; 
                padding: 12px 20px;
                color: #c3c4e1;
                &::placeholder {
                    color: #c3c4e1;
                }
            }
            .submit-btn {
                margin-top: 30px;
            }
        }
    }
    @include screen-1930 {
        background-size: 130%;
    }
    @include screen-1800 {
        background-size: 140%;
    }
    @include screen-1600 {
        background-size: 145%;
    }
    @include screen-1400 {
        background-size: cover;
        background-position: -800px 0;
    }
    @include medium-screen {
        background-size: cover;
        background-position: right center;
        .bixol-gt-right {
            padding-right: 0;
            max-width: 100%;
        }
    }
    @include portrait-phone {
        .bixol-gt-right {
            form {
                input[type='text'], 
                input[type='tel'] {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

/************************ Pricing Table *******************/ 
.bixol-pricing-table {
    .pricing-table-area {
        margin-top: 30px;
        .pricing-nav {
            text-align: center;
            ul {
                display: inline-block;
                border-bottom: 0;
                li {
                    display: inline-block;
                    margin-right: 35px;
                    a {
                        color: $heading-color;
                        font-size: 18px;
                        font-weight: 700; 
                        font-family: $heading-font;
                        text-transform: capitalize;
                        position: relative;
                        &::before {
                            content: ''; 
                            position: absolute; 
                            top: 50%; 
                            left: -26px; 
                            transform: translateY(-50%); 
                            width: 20px; 
                            height: 20px;
                            border: 2px solid $border-color;
                            border-radius: 50%;
                            transition: $transition;
                        }
                        &::after {
                            content: ''; 
                            position: absolute; 
                            top: 50%; 
                            left: -19px;
                            transform: translateY(-50%);
                            width: 6px; 
                            height: 6px;
                            border-radius: 50%;
                            background-color: $white-color;
                            transition: $transition;

                        }
                        &.active {
                            color: #0e0e0e;
                            &::before {
                                border-color: $secondary-color; 
                            }
                            &::after {
                                background-color: $secondary-color;
                            }
                        }
                    }
                }
            }
            .offer-price {
                background-color: #def8fc;
                color: $heading-color; 
                font-size: 12px;
                padding: 6px 10px;
                display: inline-block;
                margin-left: -15px;
                font-weight: 700;
                text-transform: capitalize; 
            }
        }
        .pricing-content {
            margin-top: 30px;
            .pricing-column {
                background-color: $white-color;
                box-shadow: $box-shadow;
                padding: 60px 20px 40px 20px;
                text-align: center;
                margin-bottom: 30px;
                .pc-top {
                    .pc-top-title {
                        font-size: 14px;
                        color: $secondary-color; 
                        font-family: $heading-font;
                        font-weight: 700;
                    }
                    h4 {
                        margin-bottom: 3px;
                    }
                    .pc-subtitle {
                        color: #666666;
                    }
                }
                .pc-price {
                    margin-top: 15px;
                }
                .pc-icon-wrapper {
                    margin-top: 20px;
                    img {
                        width: 80px;
                        display: inline-block;
                    }
                }
                .pc-list {
                    margin-top: 20px;
                    padding: 0 45px;
                    p {
                        font-size: 13px;
                    }
                    span {
                        font-weight: 700;
                        font-size: 18px;
                        margin-top: 8px;
                        display: inline-block;
                    }
                }
                .pc-btn {
                    a {
                        padding: 10px 28px;
                        background-color: $primary-color;
                        color: $white-color;
                        font-size: 15px;
                        font-weight: 700;
                        font-family: $heading-font;
                        margin-top: 20px;
                        display: inline-block;
                        border-radius: 3px;
                    }
                }
                &.best-choise {
                    padding-top: 35px;
                    background-color: $primary-color;
                    .pc-top {
                        h4 {
                            color: $white-color;
                        }
                        .pc-subtitle {
                            color: $white-color;
                        }
                    }
                    .pc-price {
                        h2 {
                            color: $white-color;
                        }
                    }
                    .pc-list {
                        p {
                            color: $white-color; 
                        }
                        span {
                            color: $white-color;
                        }
                    }
                    .pc-btn {
                        a {
                            background-color: $secondary-color;
                        }
                    }
                }
                @include small-screen {
                    max-width: 360px; 
                    margin-left: auto; 
                    margin-right: auto;
                }   
            }
        }
    }
}

/******************** Feedback Section ************************/ 
.bixol-feedback {
    padding: 100px 0;
    background-size: cover; 
    background-repeat: no-repeat;
    .bixol-feedback-top {
        padding: 0 60px 0 100px;
        @include medium-screen {
            padding: 0;
            text-align: center;
        }
        .bf-desc {
            @include medium-screen {
                width: 80%;
                margin: auto;
                margin-top: 20px;
            }
            @include small-screen {
                width: 100%;
            }
        }
    }
    .bixol-feedback-slider {
        margin-top: 60px;
        .feedback-column {
            position: relative;
            background-color: $white-color;
            padding: 40px 20px 50px 20px;
            &::after {  
                content: '';
                position: absolute; 
                top: -1px; 
                right: -1px;
                width: 60px; 
                height: 60px;
                background-color: #dfe6e7;
                z-index: 2;
                clip-path: polygon(0 0, 100% 100%, 100% 0);
            }
            .quote-icon {
                position: absolute;
                top: -65px; 
                left: 20px;
                display: inline-block;
                font-family: 'Playfair Display', serif;
                font-size: 160px;
                font-weight: bold;
                line-height: 0;
                letter-spacing: -13px;
                color: #adb0b4;
                transition: $transition;
            }
            .feedback-rating {
                text-align: right;
                span {
                    color: #ffc601;
                }
            }
            .feedback-client-info {
                display: flex;
                align-items: flex-end;
                gap: 20px;
                position: absolute;
                bottom: -60px;
                left: 40px;
                .client-thumb {
                    width: 95px; 
                    height: 95px;
                    border-radius: 50%;
                    position: relative;
                    img {
                        border-radius: 50%;
                        background-color: $white-color; 
                        padding: 4px;
                        position: relative; 
                        z-index: 2;
                    }
                    .svg-1 {
                        fill: #dfe6e7;
                        position: absolute;
                        top: -10px;
                        transition: $transition;
                    }
                    .svg-2 {
                        fill: #adb0b4; 
                        position: absolute;
                        bottom: -10px;
                        transition: $transition;
                    }
                }
                .clients-name {
                    padding-bottom: 5px;
                    h6 {
                        color: #3a4268; 
                        font-weight: 700;
                        font-family: $heading-font;
                    }
                    span {
                        color: #3a4268;
                        font-size: 14px;
                    }
                }
            }
            &:hover {
                .quote-icon {
                    color: $primary-color;
                }
                .feedback-client-info {
                    .client-thumb {
                        .svg-1 {
                            fill: $secondary-color;
                        }
                        .svg-2 {
                            fill: $primary-color;
                        }
                    }
                }

            }
        }
        .slick-list {
            padding: 30px 0 100px 0;
            margin: 0 -15px;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-dots {
            position: absolute;
            bottom: -60px;
            left: 50%;
            transform: translateX(-50%);
            li {
                display: inline-block;
                width: 20px; 
                height: 20px; 
                border-radius: 50%; 
                border: 1px solid transparent;
                padding: 3px;
                text-align: center; 
                line-height: 20px;
                transition: $transition;
                button {
                    font-size: 0;
                    display: block;
                    width: 100%; 
                    height: 100%;
                    border: 0; 
                    padding: 0;
                    border-radius: 50%;
                    background-color: #8b90a7;
                    transition: $transition;
                }
                &.slick-active {
                    border: 2px solid $primary-color;
                    button {
                        background-color: $primary-color;
                    }
                }
            }
        }
        @include small-screen {
            max-width: 400px; 
            margin-left: auto; 
            margin-right: auto;
        }
    }
}

/************************ Blog Area ******************************/ 
.bixol-blog-area {
    .bixol-blog-top {
        padding: 0 60px;
        margin-bottom: 60px;
        @include medium-screen {
            padding: 0; 
            .bixol-title-area {
                text-align: center;
            }
        }
        .bf-desc {
            @include medium-screen {
                width: 80%; 
                margin: auto; 
                margin-top: 20px;
                text-align: center;
            }
            @include small-screen {
                width: 100%;
            }
        }
    }
    .bixol-blog-slider {
        .bixol-single-blog {
            display: flex;
            align-items: center; 
            gap: 20px;
            box-shadow: $light-shadow;
            overflow: hidden;
            .bixol-img-wrapper {
                position: relative;
                img {
                    width: 270px;
                    transition: $transition;
                    &:hover {
                        transform: scale(1.03);
                    }
                }
                .bixol-blog-date {
                    width: 65px; 
                    height: 65px; 
                    border-radius: 3px; 
                    background-color: $primary-color; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    padding: 0 10px;
                    text-align: center;
                    position: absolute;
                    top: 10px; 
                    left: 10px;
                    span {
                        color: $white-color;
                        font-family: $heading-font; 
                        font-weight: 700; 
                        font-size: 20px;
                    }
                }
            }
            .bixol-blog-content {
                padding-right: 20px;
                .bixol-category {
                    display: inline-block; 
                    padding: 3px 6px;
                    background-color: #ced4e6; 
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $heading-color;
                }
                .bixol-blog-headline {
                    margin-top: 20px;
                    h5 {
                        text-transform: initial;
                        transition: $transition; 
                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }
                .bixol-blog-pera {
                    margin-top: 20px;
                }
                .bixol-blog-btn {
                    a {
                        margin-top: 30px;
                    }
                }
            }
        }
        .slick-list {
            padding: 30px 0;
            margin: 0 -15px;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-dots {
            position: absolute; 
            left: 50%; 
            transform: translateX(-50%); 
            bottom: -50px;
            li {
                display: inline-block;
                width: 20px; 
                height: 20px; 
                border-radius: 50%; 
                border: 1px solid transparent;
                padding: 3px;
                text-align: center; 
                line-height: 20px;
                transition: $transition;
                button {
                    font-size: 0;
                    display: block;
                    width: 100%; 
                    height: 100%;
                    border: 0; 
                    padding: 0;
                    border-radius: 50%;
                    background-color: #8b90a7;
                    transition: $transition;
                }
                &.slick-active {
                    border: 2px solid $primary-color;
                    button {
                        background-color: $primary-color;
                    }
                }
            }

        }
        @include large-screen {
            max-width: 700px; 
            margin-left: auto; 
            margin-right: auto;
        }
        @include small-screen {
            max-width: 500px;
            .bixol-single-blog {
                display: block;
                text-align: center;
                padding: 30px 30px;
                .bixol-img-wrapper {
                    margin-bottom: 20px;
                    img {
                        max-width: 100%; 
                        width: auto;
                        display: inline-block;
                    }
                }
            }
        }
    }
    .bixol-blog-btn {
        display: inline-block;
    }
}

/************************ Bixol Contact Info ***********************/ 
.bixol-contact-info {
    .bixol-title-area {
        text-align: center;
    }
    .bixol-contact-content {
        margin-top: 60px;
        .bixol-contact-column {
            display: flex;
            align-items: center;
            border: 1px solid $border-color;
            padding: 30px;
            transition: $transition;
            margin-bottom: 30px;
            .bixol-icon-wrapper {
                i {
                    font-size: 40px;
                    color: $secondary-color;
                }
            }
            .column-content {
                margin-left: 20px;
                h5 {
                    margin-bottom: 5px;
                }
            }
            &:hover {
                box-shadow: $box-shadow;
            }
            @include small-screen {
                max-width: 450px;
                margin-left: auto; 
                margin-right: auto;
            }
        }
    }
}


/******************* Google Map ****************/ 
.bixol-footer-top {
    iframe {
        width: 100%; 
        height: 275px;
    }
}


