
.home2-hero-slider {
    position: relative;
    .container {
        position: relative;
    }
    .appoinment-form {
        position: absolute;
        top: -760px;
        right: 0;
        display: inline-block;
        z-index: 2;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 80px 40px;
        max-width: 400px;
        border-radius: 6px;
        .appoinment-title {
            span {
                color: #d3d4eb; 
                font-size: 15px;
            }
            h4 {
                color: $white-color;
            }
        }
        form {
            margin-top: 20px;
            input {
                display: block;
                margin-bottom: 15px;
                width: 100%;
                padding: 13px 15px;
                background-color: #22356f; 
                font-size: 14px;
                border: 0;
                border-radius: 2px; 
                color: $white-color;
                &::placeholder {
                    color: $white-color;
                }
            }
            select {
                width: 100%;
                padding: 10px 15px; 
                background-color: #22356f; 
                border: 0; 
                color: $white-color;
                margin-bottom: 15px;
                font-size: 14px;
                border-radius: 2px;
            }
            textarea {
                width: 100%; 
                padding: 10px 15px; 
                background-color: #22356f; 
                border: 0; 
                color: $white-color; 
                font-size: 14px;
                border-radius: 2px;
                &::placeholder {
                    color: $white-color; 
                }
            }
            button {
                border-radius: 30px;
                height: 48px;
                font-size: 14px;
                margin-top: 30px;
                span {
                     border-radius: 50%;
                     width: 35px; 
                     height: 35px;
                     display: inline-flex;
                     align-items: center; 
                     justify-content: center;
                     i {
                         line-height: 15px;
                     }
                 }
            }

        }
        @include large-screen {
            display: none;
        }
    }
}

/************** Home2 About Section **************/ 
.home2-about-section {
    .about-left {
        .img-wrapper {
            position: relative; 
            width: 76%;
            .es-year {
                position: absolute;
                right: 0; 
                top: 40%; 
                transform: translate(50%, -50%); 
                width: 120px; 
                height: 120px; 
                background-color: $info-bg; 
                border-radius: 50%;
                padding: 10px;
                .year-content {
                    border: 2px dashed $secondary-color;
                    border-radius: 50%;
                    width: 100%; 
                    height: 100%;
                    display: flex; 
                    align-items: center; 
                    justify-content: center; 
                    flex-direction: column; 
                    font-size: 12px;
                    color: $white-color;
                    span.icon-wrapper {
                        font-size: 20px;
                        color: $secondary-color;
                    }
                    span.title {
                        font-size: 18px; 
                        font-family: $heading-font; 
                        font-weight: 700;
                    }

                }
            }
        }
        .about-left-content {
            margin-top: -80px;
            margin-left: 60px;
            position: relative; 
            z-index: 10;
            display: flex; 
            .about-counterup {
                display: flex;
                align-items: center;
                .icon-container {
                    span {
                        color: $secondary-color; 
                        font-size: 40px;
                    }
                }
                .counterup-content {
                    margin-left: 15px;
                    .title {
                        h4 {
                            font-size: 32px;
                            sup {
                                color: $secondary-color;
                            }
                        }
                    }
                    .subtitle {
                        span {
                            font-weight: 500; 
                            font-size: 15px;
                        }
                    }
                }
                + .about-counterup {
                    position: relative; 
                    margin-left: 60px;
                    &::before {
                        content: ''; 
                        position: absolute; 
                        width: 0; 
                        height: 50px; 
                        border: 1px dashed $border-color; 
                        left: -35px; 
                        top: 50%; 
                        transform: translateY(-50%);
                    }
                }
            }
        } 
        @include medium-screen {
            margin-bottom: 60px;
            .img-wrapper {
                width: auto; 
                margin-left: auto; 
                margin-right: auto;
                display: inline-block;
                text-align: center;
                img {
                    max-width: 100%; 
                    width: auto;
                }
            }
        }
        @include small-screen {
            .about-left-content {
                width: 100%;
                .about-counterup {
                    display: block;
                    text-align: center;
                }
            }
        }
        @include screen-460 {
            .about-left-content {
                margin-left: 20px;
            }
        }       
    }
    .about-right {
        .bixol-title-area {
            h3 {
                text-transform: initial;
            }
        }
        .seperator {
            hr {
                margin: 30px 0 30px 0;
                border: 0; 
                border-top: 2px solid $border-color;
                width: 95%;
            }
        }
        .about-content {
            ul {
                li {
                    display: flex; 
                    gap: 10px;
                    + li {
                        margin-top: 20px;
                    }
                    i {
                        transform: translateY(5px);
                        color: $primary-color;
                    }
                }
            }
        }
        .about-btn {
            margin-top: 30px;
            a {
                border-radius: 30px;
                height: 52px;
                padding: 0 10px 0 20px;
                span {
                    border-radius: 50%;
                }
            }
        }
    }
}

/**************** Home 2 Service Section **************/ 
.home2-service-section {
    background-size: cover; 
    background-repeat: no-repeat;
    .home2-service-slider {
        margin-top: 30px;
        @include small-screen {
            max-width: 400px;
            margin-left: auto; 
            margin-right: auto;
        }
        .service-single-item {
            background-color: $white-color; 
            box-shadow: $light-shadow;
            padding: 40px;
            text-align: center;
            border-radius: 6px;
            position: relative; 
            margin-bottom: 50px;
            .img-wrapper {
                width: 90px; 
                height: 90px; 
                border-radius: 50%;
                display: inline-block;
                border: 2px dashed $primary-color;
                overflow: hidden;
                padding: 15px;
            }
            .srv2-service-content {
                h5 {
                    margin-top: 20px;
                    position: relative; 
                    &::after {
                        content: ''; 
                        width: 50px; 
                        height: 3px; 
                        background-color: $secondary-color; 
                        position: absolute; 
                        bottom: -15px; 
                        left: 50%; 
                        transform: translateX(-50%);
                    }
                }
                p {
                    margin-top: 30px;
                }
            }
            .srv2-hover-item {
                position: absolute; 
                top: 50%; 
                left: 0;
                transform: translateY(-50%) scale(0.9);
                padding: 40px;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 1;
                border-radius: 6px;
                opacity: 0;
                visibility: hidden;
                transition: $transition;
                &::after {
                    content: ''; 
                    position: absolute; 
                    width: 100%; 
                    height: 100%; 
                    background-color: rgba(6, 31, 104, 0.89);
                    top: 0; 
                    left: 0; 
                    z-index: -1;
                    border-radius: 6px;
                }
                .img-wrapper {
                    background-color: $secondary-color;
                    border: 2px solid $secondary-color;
                }
                .srv2-service-content {
                    h5 {
                        color: $white-color;
                    }
                    p {
                        color: $white-color;
                    }
                    a.srv2-readmore-btn {
                        margin-top: 20px;
                        display: inline-block;
                        color: $white-color;
                        font-family: $heading-font; 
                        font-weight: 700; 
                        font-size: 14px;
                        transition: $transition;
                        i {
                            color: $secondary-color;
                            transition: $transition;
                        }
                        &:hover {
                            color: $primary-color; 
                            transform: translateX(-2px);
                            i {
                                color: $primary-color;
                                transform: translateX(3px);
                            }
                        }
                    }
                }
                @include small-screen {
                    padding: 40px 20px;
                }
            }
            &:hover {
                .srv2-hover-item {
                    opacity: 1; 
                    visibility: visible; 
                    transform: translateY(-50%) scale(1);
                    .img-wrapper {
                        animation: .3s ct_fadeInDown linear;
                    }
                    .srv2-service-content {
                        h5 {
                            animation: 0.5s ct_fadeInUp linear; 
                        }
                        p {
                            animation: 0.7s ct_fadeInUp linear; 
                        }
                        a {
                            animation: 1s ct_fadeInUp linear;
                        }
                    }
                }
            }
        }
        .slick-list {
            padding: 30px 0; 
            margin: 0 -15px;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-arrow {
            position: absolute; 
            left: -60px; 
            top: 50%; 
            transform: translateY(-50%);
            width: 40px; 
            height: 40px;
            background-color: #cddae6; 
            border: 0; 
            padding: 0; 
            border-radius: 50%;
            text-align: center; 
            line-height: 40px;
            color: $heading-color;
            z-index: 10;
            transition: $transition; 
            &:hover {
                background-color: $primary-color; 
                color: $white-color;
            }
            &.next-btn {
                right: -60px;
                left: auto;
            }
        }
    }
}


/************ Home2 PMV section ************/
.home2-pmv-section {
    .pmv-top {
        padding: 0 60px;
        .bf-desc {
            @include medium-screen {
                margin-top: 20px;
            }
        }
        @include portrait-phone {
            padding: 0;
            text-align: center;
        }
    }
    .pmv-bottom {
        margin-top: 30px; 
        padding: 0 80px;
        .pmv-nav {
            ul {
                display: flex; 
                justify-content: space-between;
                position: relative; 
                &::after {
                    content: ''; 
                    position: absolute; 
                    bottom: -8px; 
                    left: 0; 
                    width: 100%; 
                    height: 1px; 
                    background-color: $border-color;
                }
                li {
                    display: block;
                    width: 33%;
                   a {
                       text-align: center;
                       display: block;
                       font-family: $heading-font; 
                       font-weight: 600; 
                       color: #8b90a7;
                       position: relative; 
                       transition: $transition;
                       &::after {
                           content: ''; 
                           position: absolute; 
                           bottom: -9px; 
                           right: 0; 
                           width: 0; 
                           height: 3px;
                           background-color: $secondary-color;
                           transition: $transition;
                           z-index: 2;
                       }
                       &.active {
                           color: $heading-color;
                           &::after {
                               width: 100%;
                               right: auto; 
                               left: 0;
                           }
                       }
                   }
                }
            }
            @include screen-460 {
                ul {
                    display: block;
                    text-align: center;
                    li {
                        display: block;
                        width: 100%;
                        + li {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        .tab-content {
            margin-top: 40px;
            .tab-pane {
                background-color: #e6ebf0;
                .pmv-content {
                    padding: 0 30px;
                    p {
                        margin-top: 20px;
                    }
                    a {
                        margin-top: 30px;
                        border-radius: 30px;
                        padding: 0 20px;
                        height: 45px;
                    }
                }
                @include medium-screen {
                    text-align: center;
                    padding: 30px;
                    .img-wrapper {
                        img {
                            max-width: 100%;
                            width: auto;
                            display: inline-block;
                        }
                    }
                    .pmv-content {
                        margin-top: 30px;
                    }
                }
                @include portrait-phone {
                    .pmv-content {
                        padding: 0;
                    }
                }
            }
        }
        @include portrait-phone {
            padding: 0;
        }
    }
}


/****************** Home2 Skills Area ********************/ 
.home2-skills-area {
    .home2-skills-column {
        text-align: center;
        padding: 0 25px;
        margin-bottom: 60px;
        .skills-progress {
            display: inline-block; 
            position: relative;
            .skills-value {
                position: absolute; 
                top: -35px; 
                right: 0; 
                width: 40px; 
                height: 40px; 
                background-color: $secondary-color; 
                color: $primary-color; 
                font-size: 12px; 
                font-weight: 700;
                display: inline-flex; 
                align-items: center; 
                justify-content: center;
                border-radius: 50%;
                border: 3px solid $primary-color;
            }
            .pie-value {
                display: inline-block; 
                position: absolute; 
                line-height: 0;
                top: 50%; 
                left: 50%; 
                transform: translate(-50%, -50%);
                color: $primary-color;
            }
        }
        .skills-content {
            margin-top: 15px;
            h5 {
                position: relative; 
                &::after {
                    content: ''; 
                    position: absolute; 
                    bottom: -8px; 
                    left: 50%; 
                    transform: translateX(-50%);
                    width: 40px; 
                    height: 4px;
                    background-color: $secondary-color;
                }
            }
            p {
                margin-top: 20px;
            }
        }
    }
}

/***************** Steps Area *******************/
.home2-steps-area {
    .steps-top {
        padding: 0 60px; 
        @include medium-screen {
            padding: 0; 
            text-align: center;
            .bf-desc {
                margin-top: 20px;
            }
        }
    }
    .steps-bottom {
        margin-top: 60px;
        .steps-column {
            padding: 30px;
            text-align: center; 
            margin-bottom: 30px;
            .icon-wrapper {
                span {
                    width: 100px; 
                    height: 100px; 
                    background-color: #f1f3f8; 
                    display: inline-flex; 
                    align-items: center; 
                    justify-content: center; 
                    color: $primary-color;
                    line-height: 0;
                    border-radius: 50%; 
                    font-size: 40px;
                    transition: $transition;
                    position: relative; 
                    &::after {
                        content: ''; 
                        position: absolute; 
                        bottom: -10px; 
                        left: 2px; 
                        width: 100%; 
                        height: 100%; 
                        border-radius: 50%; 
                        background-color: $secondary-color;
                        z-index: -1;
                    }
                }
            }
            .title {
                margin-top: 25px;
                h6 {
                    position: relative; 
                    text-transform: initial;
                    &::after {
                        content: ''; 
                        position: absolute; 
                        bottom: -10px; 
                        left: 50%; 
                        transform: translateX(-50%); 
                        width: 40px; 
                        height: 2px; 
                        background-color: $secondary-color;
                    }
                }
            }
            .desc {
                margin-top: 25px;
            }
            &.cl-2 {
                position: relative; 
                &::after {
                    content: ''; 
                    position: absolute; 
                    left: -30px; 
                    top: 80px;
                    width: 60px; 
                    height: 20px;
                    background-image: url(../../images/home2/arrow1.png);
                    background-size: 60px;
                    background-repeat: no-repeat;  
                }
            }
            &.cl-3 {
                position: relative; 
                &::after {
                    content: ''; 
                    position: absolute; 
                    left: -30px; 
                    top: 80px;
                    width: 60px; 
                    height: 20px;
                    background-image: url(../../images/home2/arrow2.png);
                    background-size: 60px;
                    background-repeat: no-repeat;  
                }
            }

            &:hover {
                .icon-wrapper {
                    span {
                        background-color: $primary-color;
                        color: $white-color;
                    }
                }
            }
            @include medium-screen {
                &::after {
                    display: none;
                }
            }
            @include small-screen {
                max-width: 460px; 
                margin-left: auto; 
                margin-right: auto;
            }
        }
        .steps-btn {
            text-align: center;
            a {
                border-radius: 30px; 
                height: 45px; 
                padding: 0 20px;
            }
        }
    }
}

/************** Home2 FAQ Area **************/ 

.home2-faq-area {
    background-size: cover; 
    background-repeat: no-repeat; 
    @include medium-screen {
        background-position: right bottom;
    }
    .faq-content {
        .faq-wrapper {
            margin-top: 30px;
            .accordion-item {
                position: relative;
                transition: $transition;
                &::after {
                    content: ''; 
                    position: absolute; 
                    left: 0; 
                    bottom: 0; 
                    width: 5px; 
                    height: 0; 
                    background-color: $primary-color;
                    transition: $transition;
                }
                .accordion-header {
                    a {
                        text-transform: initial;
                        color: $heading-color;
                        font-family: $heading-font; 
                        font-weight: 700;
                        position: relative;
                        font-size: 18px;
                        padding: 20px 55px 20px 25px;
                        display: block;
                        position: relative; 
                        &::after {
                            content: '\f107';
                            font-family: 'Font Awesome 5 Free'; 
                            font-weight: 900;
                            position: absolute; 
                            color: #9ba2aa;
                            font-size: 14px;
                            right: 20px;
                            top: 20px; 
                            width: 20px; 
                            height: 20px;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 20px;
                            transition: $transition;

                        }
                    }
                }
                .accordion-body {
                    padding: 0 35px 15px 25px;
                }
                + .accordion-item {
                    margin-top: 20px;
                }
                &.active {
                    box-shadow: $box-shadow;
                    &::after {
                        height: 100%; 
                        top: 0; 
                        bottom: auto;
                    }
                    .accordion-header {
                        a {
                            &::after {
                                content: '\f00d'; 
                                font-size: 10px; 
                                background-color: #9ba2aa; 
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/************* Brand Area ****************/ 
.home2-brand-area {
    .brand-wrapper {
        padding: 0 60px;
        .img-wrapper {
            text-align: center;
            width: auto; 
            height: 50px; 
            overflow: hidden;
            margin: 0 auto;
            display: block;
            img {
                width: auto; 
                max-width: 100%;
                display: block;
                margin-left: auto; 
                margin-right: auto;
                filter: grayscale(100%);
                transition: $transition;
                + img {
                    margin-top: 25px;
                }
            }
            &:hover {
                img {
                    filter: grayscale(0);
                    &:first-child {
                        margin-top: -60px;
                    }
                }
            }
        }
        .seperator {
            margin-top: 30px;
            display: block;
            hr {
                margin: 0;
                border: 0; 
                border-top: 2px solid $border-color;

            }
        }
        @include medium-screen {
            padding: 0;
        }
    }
}

.home2-blog-area {
    .blog-top-title {
        padding: 0 60px;
        @include medium-screen {
            padding: 0; 
            text-align: center;
            .bf-desc {
                margin-top: 20px;
            }
        }
    }
    .home2-blog-slider {
        margin-top: 60px;
        .blog-single-item {
            position: relative; 
            .thumb-wrapper {
                position: relative; 
                border-radius: 6px;
                overflow: hidden;
                img {
                    transition: $transition;
                }
                &::before {
                    content: ''; 
                    position: absolute; 
                    top: 0; 
                    left: -100px; 
                    width: 80px; 
                    height: 100%;
                    background-image: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%); 
                    transform: skew(-10deg);
                    transition: $transition; 
                    z-index: 10;
                }
            }
            .blog-content {
                width: 80%;
                height: auto;
                background-color: $white-color; 
                box-shadow: $box-shadow; 
                border-radius: 6px;
                padding: 20px;
                margin-left: auto; 
                margin-right: auto;
                margin-top: -60px;
                position: relative; 
                z-index: 15;
                transition: $transition;
                .blog-meta {
                    overflow: hidden;
                    transition: $transition;
                    height: 25px;
                    span {
                        color: #72799a; 
                        font-size: 14px;
                        margin-right: 15px;
                        i {
                            margin-right: 6px;
                        }
                    }
                }
                .blog-title {
                    margin-top: 6px;
                    h5 {
                        font-size: 20px;
                        transition: $transition;
                        &:hover {
                            color: $secondary-color;
                        }
                    }
                }
                .readmore-btn {
                    margin-top: 6px;
                    height: 0;
                    overflow: hidden;
                    transition: $transition;
                    a {
                        font-family: $heading-font; 
                        font-weight: 700;
                        font-size: 15px;
                        color: $secondary-color;
                    }
                }
            }
            &:hover {
                .thumb-wrapper {
                    img {
                        transform: scale(1.06);
                    }
                    &::before {
                        animation: 800ms shine; 
                    }
                }
                .blog-content {
                    box-shadow: 0px 20px 29px 0px rgba(5, 22, 72, 0.2);;
                    .blog-meta {
                        height: 0;
                    }
                    .readmore-btn {
                        height: 25px;
                    }
                }
            }
        }
        .slick-list {
            padding-bottom: 60px; 
            margin: 0 -15px; 
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-dots {
            position: absolute; 
            bottom: 0px; 
            left: 50%;
            transform: translateX(-50%); 
            li {
                display: inline-block;
                margin-right: 6px;
                button {
                    width: 10px; 
                    height: 10px; 
                    font-size: 0; 
                    border: 2px solid transparent; 
                    background-color: #8b90a7; 
                    border-radius: 50%; 
                    padding: 0;
                    transition: $transition;
                }
                &.slick-active {
                    button {
                        width: 16px; 
                        height: 16px; 
                        border: 2px solid $primary-color;
                        background-color: $secondary-color;
                    }
                    
                }
            }
        }
        @include small-screen {
            max-width: 460px; 
            margin-left: auto; 
            margin-right: auto;
        }
    }
}

/************ Home 2 Contact Area ***************/
.home2-contact-area {
    overflow: visible;
    .home2-git {
        padding: 60px 30px 10px 30px;
        background-size: cover; 
        border-radius: 6px 6px 0 0;
        .title {
            margin-bottom: 60px;
            h3 {
                color: $white-color;
                span {
                    display: inline;
                    font-weight: 400;
                }
            }
        }
        .git-bottom {
            position: relative;
            display: flex;
            align-items: center;
            gap: 30px;
            .git-txt {
                color: $white-color; 
                text-align: center; 
                position: relative;
                z-index: 1;
                &::before {
                    content: ''; 
                    width: 35px; 
                    height: 35px; 
                    background-image: linear-gradient( 0deg, rgb(0,106,204) 0%, rgb(1,213,255) 100%);
                    position: absolute; 
                    left: 50%; 
                    top: 50%; 
                    transform: translate(-50%, -50%) rotate(45deg);
                    z-index: -1; 
                    border-radius: 4px;
                }
                span {
                    position: absolute; 
                    top: 50%; 
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -2;
                    width: 3px; 
                    height: 125px;
                    border-right: 3px dashed $secondary-color;
                    &::before {
                        content: ''; 
                        position: absolute; 
                        top: 0; 
                        left: 0; 
                        width: 3px; 
                        height: 30px;
                        background-image: linear-gradient(bottom,rgba(14,42,71,0),#0e2a47);

                    }
                    &::after {
                        content: ''; 
                        position: absolute; 
                        bottom: 0px; 
                        left: 0;
                        width: 3px; 
                        height: 30px; 
                        background-image: linear-gradient(top,rgba(14,42,71,0),#0e2a47);
                    }
                }
            }
            .git-title {
                display: block;
                font-size: 18px; 
                color: $white-color; 
                font-family: $nunito-sans;
                font-weight: 700;
                text-decoration: underline;
            }
            a {
                color: $white-color; 
                font-size: 24px; 
                font-family: $nunito-sans; 
                font-weight: 700;
                margin-top: 6px;
                display: inline-block;
                span {
                    color: $secondary-color; 
                    margin-right: 10px;
                    transform: translateY(3px);
                    display: inline-block;
                }
            }
        }
        .git-right {
            text-align: right;
            img {
                max-width: 100%; 
                width: auto;
                margin-top: -100px;
                position: relative; 
                display: inline-block;
                z-index: 10;
            }
        }
        @include medium-screen {
            padding-bottom: 160px;
            .git-left {
                margin-top: 30px;
                text-align: center;
                .git-bottom {
                    text-align: left;
                    display: inline-flex;
                }
            }
            .git-right {
                text-align: center;
            }
        }
        @include small-screen {
            .git-left {
                .git-bottom {
                    a {
                        font-size: 22px;
                    }
                }
            }
        }
        @include portrait-phone {
            .git-left {
                .git-bottom {
                    display: block;
                    text-align: center;
                    .git-txt {
                        margin: 25px 0;
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


