/* Header Section */

//Header 1 
.bixol-header {
    position: relative; 
    z-index: 10;
    .bixol-info-bar {
        padding: 6px 0; 
        background-color: $info-bg;
        .bixol-info-left {
            p {
                font-size: 13px;
                color: $white-color;
                span {
                    font-weight: 500;
                }
            }
        }
        .bixol-info-social {
            text-align: right;
            a {
                color: #748bd1;
                display: inline-block;
                transition: $transition;
                &:hover {
                    color: $white-color;
                }
                + a {
                    margin-left: 10px;
                }
            }
        }
    }
    .bixol-header-top {
        padding: 15px 0;
        .bixol-header-top-content {
            padding: 0 50px;
        }
        .bixol-header-top-left {
            display: flex; 
            gap: 60px;
            .bixol-header-item {
                &:first-child {
                    &::after {
                        content: '';
                        position: absolute; 
                        top: 50%; 
                        right: -32px;
                        transform: translateY(-50%);
                        width: 1px; 
                        height: 30px;
                        border: 1px dashed #c7d1ef;
                    }
                }
            }
        }
        .bixol-header-item {
            display: flex; 
            align-items: center;
            position: relative;
            .bixol-icon-wrapper {
                display: inline-block;
                span {
                    display: inline-block;
                    line-height: 0;
                }
                i {
                    font-size: 40px;
                    display: inline-block;
                    color: $heading-color;
                }
            }
            .bixol-item-content {
                margin-left: 10px;
                label {
                    color: $heading-color;
                    display: block;
                    font-size: 13px;
                }
                span {
                    font-size: 19px;
                    font-family: $heading-font;
                    font-weight: 700; 
                    font-size: 19px;
                    color: $heading-color;
                    display: inline-block;
                }
            }
            a {
                position: absolute; 
                top: 0; 
                left: 0; 
                width: 100%; 
                height: 100%;
            }
        }
        .bixol-header-top-right {
            display: flex; 
            gap: 60px; 
            justify-content: flex-end;
            .bixol-header-item {
                &:first-child {
                    &::after {
                        content: ''; 
                        position: absolute; 
                        right: -32px; 
                        top: 50%; 
                        transform: translateY(-50%);
                        width: 1px; 
                        height: 30px; 
                        border: 1px dashed #c7d1ef;
                    }
                }
            }
        }
    }
    .bixol-navigation {
        .bixol-nav {
            background-color: $primary-color;
            position: relative;
            z-index: 12;
            &::after {
                content: ''; 
                position: absolute; 
                bottom: -6px; 
                left: 50%; 
                transform: translateX(-50%); 
                width: 95%; 
                height: 6px; 
                background-color: $secondary-color;
                z-index: 10;
            }
            .bixol-header-logo {
                display: inline-block; 
                position: absolute; 
                top: 50%; 
                left: 50%; 
                transform: translate(-50%, -50%);
                width: 130px;
                border-radius: 50%;
                box-shadow: $box-shadow;
                z-index: 15;
            }
        }
    }
    .bixol-mobile-header {
        display: none;
        padding: 6px 0;
        a {
            width: 130px;
            display: inline-block;
        }
    }

    @include large-screen {
        .bixol-header-top {
            display: none;
        }
        .bixol-navigation {
            .bixol-nav {
                display: none;
            }
        }
        .bixol-mobile-header {
            display: block;
        }
    }

    @include portrait-phone {
        .bixol-info-bar {
            display: none;
        }
    }

}

.bixol-mobile-hamburger {
    float: right;
    width: 35px;
    span {
        width: 30px; 
        height: 4px; 
        background-color: $black-color;
        display: block; 
        transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
        border-radius: 3px;
        &:nth-of-type(1){
            width:50%;
        }
        &:nth-of-type(2){
            width:100%;
        }
        &:nth-of-type(3){
            width:75%;
        }
        + span {
            margin-top: 4px;
        }
    }
    &.active {
        span {
            &:nth-of-type(1) {
                transform-origin:bottom;
                transform:rotatez(45deg) translate(3px,-2px)
            }
            &:nth-of-type(2) {
                transform-origin:top;
                transform:rotatez(-45deg)
            }
            &:nth-of-type(3) {
                transform-origin:bottom;
                width:50%;
                transform: translate(15px,-5px) rotatez(45deg);
            }
        }
    }
}


/************************ Header Style 2 **********************/ 
.header-style-2 {
    position: relative;
    z-index: 10;
    .info-bar {
        background-color: $info-bg;
        padding: 8px 0;
        .info-left {
            padding-left: 145px;
            @include large-screen {
                padding-left: 0;
            }
            span {
                color: $white-color; 
                font-size: 14px; 
                i {
                    color: $secondary-color; 
                    margin-right: 6px;
                }
                + span {
                    margin-left: 60px;
                    position: relative; 
                    &::before {
                        content: ''; 
                        position: absolute; 
                        top: 50%; 
                        transform: translateY(-50%);
                        left: -30px; 
                        width: 0px; 
                        height: 20px; 
                        border: 1px dashed #313c5f;
                    }
                }
            }
        }
        .header-social {
            text-align: right; 
            a {
                color: #748bd1; 
                transition: $transition;
                &:hover {
                    color: $white-color;
                }
                + a {
                    margin-left: 10px;
                }
            }
        }
        @include small-screen {
            display: none;
        }
    }
    .header-bottom {
        background-color: $secondary-color;
        .logo-wrapper {
            position: relative;
            a.desktop-logo {
                position: absolute; 
                top: -70px; 
                left: 0;
                background-color: $white-color;
                border-radius: 3px;
                width: 120px;
                box-shadow: $box-shadow;
                display: inline-block;
                @include large-screen {
                    position: initial;
                    width: 60px;
                    vertical-align: middle;
                }
            }
        }
        .header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .bixol-mobile-hamburger {
                margin-left: 15px;
                display: none;
                span {
                    background-color: $white-color;
                }
                @include large-screen {
                    display: inline-block;
                }
            }
        }
        .header-search {
            text-align: right;
            form {
                max-width: 215px;
                position: relative; 
                display: inline-block;
                input[type='text'] {
                    width: 100%; 
                    padding: 3px 20px;
                    border: 1px solid $white-color;
                    border-radius: 30px;
                    background-color: transparent;
                    color: $white-color;
                    &::placeholder {
                        color: $white-color; 
                        font-size: 14px;
                    }
                }
                button {
                    position: absolute; 
                    right: 12px; 
                    background-color: transparent; 
                    color: $white-color; 
                    font-size: 14px; 
                    border: 0;
                    top: 50%; 
                    transform: translateY(-50%);

                }
            }
        }
        @include small-screen {
            padding: 6px 0;
        }
    }
}


/************************ Header Style 3 ********************/ 
.header-style-3 {
    .info-bar {
        background-color: $home3-primary; 
        .info-left {
            padding-left: 0;
        }
    }
    .header-bottom {
        background-color: $white-color;
        .logo-wrapper {
            a.desktop-logo {
                position: initial;
                background-color: transparent;
                box-shadow: none;
            }
        }
        .header-search {
            form {
                input[type="text"] {
                    border-color: $home3-primary;
                    color: $home3-primary;
                    &::placeholder {
                        color: $home3-primary;
                    }
                }
                button {
                    color: $home3-primary;
                }
            }
        }
    }
    @include large-screen {
        .info-bar {
            display: none;
        }
        .header-bottom {
            padding: 10px 0;
            .logo-wrapper {
                a.desktop-logo {
                    width: 120px;
                }
            }
            .header-right {
                .bixol-mobile-hamburger {
                    span {
                        background-color: $primary-color;
                    }
                }
            }
            
        }
    }
    @include screen-460 {
        .header-bottom {
            .header-right {
                .header-search {
                    form {
                        max-width: 150px;
                    }
                }
            }
        }
    }
}

/*************** Header style 4 ****************/ 
.header-style-4 {
    box-shadow: $box-shadow;
    background-color: $white-color;
    .h4-info-area {
        border-bottom: 1px solid $border-color;
        .logo-wrapper {
            a {
                width: 120px;
                display: inline-block;
            }   
        }
        .h4-info-items {
            text-align: right;
            .h4-info-item {
                display: inline-flex; 
                align-items: center;
                position: relative;
                + .h4-info-item {
                    margin-left: 60px;
                    &::before {
                        content: ''; 
                        position: absolute; 
                        top: 50%; 
                        left: -30px; 
                        transform: translateY(-50%); 
                        width: 0px; 
                        height: 30px; 
                        border-left: 1px dashed $border-color;
                    }
                }
                .bixol-icon-wrapper {
                    display: inline-block;
                    i {
                        font-size: 30px;
                        line-height: 0;
                        display: inline-block;
                        transform: translateY(5px);
                        color: $heading-color;
                    }
                }
                .bixol-item-content {
                    margin-left: 10px;
                    text-align: left;
                    label {
                        color: $black-color;
                        display: block;
                        font-size: 13px;
                    }
                    span {
                        font-size: 19px;
                        font-family: $heading-font;
                        font-weight: 700; 
                        font-size: 19px;
                        color: $black-color;
                        display: inline-block;
                    }
                }
                a {
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%;
                }
            }
        }
    }
    .header-md-logo {
        display: none;
        width: 120px;
        position: absolute; 
        top: 50%;
        left: 15px; 
        transform: translateY(-50%);
    }
    .h4-header-search {
        border-left: 1px solid $border-color;
        text-align: center;
        span {
            cursor: pointer;
        }
    }
    .header-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .bixol-mobile-hamburger {
            display: none;
            margin-left: 15px;
        }
        .h4-header-social {
            text-align: right;
            a {
                width: 30px; 
                height: 30px; 
                display: inline-block;
                text-align: center; 
                line-height: 30px; 
                color: #5a5a5a;
                transition: $transition; 
                &:hover {
                    color: $white-color; 
                    background-color: $home4-secondary;
                }
            }
        }
    }
    
    @include large-screen {
        padding: 20px 0;
        .header-md-logo {
            display: inline-block;
        }
        .h4-info-area {
            display: none;
        }
        .header-right {
            .h4-header-social {
                display: none;
            }
            .bixol-mobile-hamburger {
                display: inline-block;
            }
        }
    }
    @include medium-screen {
        .h4-header-search {
            border-left: 0;
            text-align: right;
        }
        .header-right {
            .h4-header-social {
                display: inline-block;
            }
        }
    }
    @include screen-460 {
        .h4-header-search {
            display: none;
        }
        .header-right {
            .h4-header-social {
                display: none;
            }
        }
        
    }
    
}

/************* Header Style 5 ****************/ 
.header-style-5 {
    background-color: $white-color; 
    .info-top {
        padding: 10px 0; 
        border-bottom: 1px solid $border-color;
        .info-left {
            p {
                font-size: 14px;
                span {
                    font-weight: 500;
                }
            }
        }
        .info-right {
            text-align: right;
            div {
                display: inline-block;
                position: relative;
                span {
                    font-size: 14px;
                    i {
                        margin-right: 6px;
                    }
                }
                + div {
                    margin-left: 40px;
                    &::before {
                        content: ''; 
                        position: absolute; 
                        top: 50%; 
                        left: -20px; 
                        transform: translateY(-50%); 
                        width: 0; 
                        height: 20px; 
                        border: 1px solid $border-color;
                    }
                }
            }
            .info-social {
                a {
                    color: $black-color;
                    transition: $transition; 
                    &:hover {
                        color: $home5-primary;
                    }
                    + a {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .header-navigation {
        .logo-wrapper {
            a {
                width: 130px;
                display: inline-block;
            }
        }
        .header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .header-btn {
                text-align: right;
            }
            .bixol-mobile-hamburger {
                margin-left: 15px;
                display: none;
            }
        }
        
    }
    @include large-screen {
        .header-navigation {
            .header-right {
                .header-btn {
                    display: none;
                }
                .bixol-mobile-hamburger {
                    display: inline-block;
                }
            }
        }
    }
    @include medium-screen {
        padding: 10px 0;
        .info-top {
            display: none;
        }
    }
}

/************** Header Style 6 **************************/ 
.header-style-6 {
    background-color: $home6-primary;
    .logo-wrapper {
        position: relative;
        a.desktop-logo {
            position: absolute;
            top: -35px;
            left: 0;
            width: 130px;
            display: inline-block;
            background-color: $white-color;
            vertical-align: middle;
            padding: 0 15px;
            border-radius: 0 0 6px 6px;
            box-shadow: $box-shadow;
        }
        a.responsive-logo {
            display: none;
            background-color: $white-color;
            padding: 10px;
            border-radius: 4px;
            vertical-align: middle;
        }
    }
    .header-btn {
        text-align: right;
        a {
            width: 150px; 
            height: 50px; 
            background-color: $home6-secondary; 
            color: $heading-color; 
            font-family: $heading-font; 
            font-weight: 600; 
            font-size: 15px;
            display: inline-block;
            text-align: center; 
            line-height: 50px;
            border-radius: 3px;
            text-transform: capitalize;
            overflow: hidden;
            vertical-align: middle; 
            position: relative; 
            &::before {
                content: ''; 
                position: absolute; 
                top: 0; 
                left: -60px; 
                width: 50px; 
                height: 100%; 
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0.658), rgba(255, 255, 255, 0.301));
                transform: skew(-10deg);
            }
            &:hover {
                &::before {
                    animation: 800ms shine;
                }
            }
        }
    }
    @include large-screen {
        padding: 10px 0;
        .logo-wrapper {
            a.desktop-logo {
                display: none;
            }
            a.responsive-logo {
                display: inline-block;
            }
        }
        .header-right {
            .bixol-mobile-hamburger {
                span {
                    background-color: $white-color;
                }
            }
        }
    }
    @include portrait-phone {
        .header-right {
            .header-btn {
                display: none;
            }
        }
    }
}

/*************** Header Style 7 *****************/
.header-style-7 {
    .home7-infobar {
        background-color: #000921;
        padding: 12px 0;
        .info-left {
            p {
                color: $white-color; 
                text-transform: capitalize;
                font-size: 13px;
                span {
                    color: $home7-primary;
                }
            }
        }
        .info-social {
            text-align: right;
            a {
                color: #777777; 
                display: inline-block;
                transition: $transition; 
                + a {
                    margin-left: 6px;
                }
                &:hover {
                    color: $home7-primary;
                }
            }
        }
    }
    .header-navigation {
        background-color: transparent;
        padding-top: 6px;
        padding-bottom: 6px;
        .logo-wrapper {
            a {
                width: 140px;
                display: inline-block;
            }
        }
        .desktop-menu {
            padding-left: 0;
        }
        .header-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .header-search {
                display: inline-block; 
                form {
                    position: relative;
                    max-width: 215px;
                    border-radius: 30px 0 0 30px;
                    input[type="text"] {
                        width: 100%;
                        padding: 13px 15px 13px 45px; 
                        border: 0; 
                        border-radius: 30px 0 0 30px;
                        font-family: $heading-font; 
                        color: #666666;
                        &::placeholder {
                            color: #666666;
                        }
                    }
                    button {
                        position: absolute; 
                        top: 50%; 
                        left: 12px;
                        transform: translateY(-50%);
                        border: 0; 
                        background-color: transparent;
                    }
                }
            }
            .ct-cart-btn {
                display: inline-flex;
                justify-content: flex-end;
                padding-right: 18px;
                border-radius: 0 30px 30px 0;
                width: 50px; 
                height: 50px;
                background-color: $white-color;
                margin-left: -6px;
                position: relative;
                &::before {
                    content: ''; 
                    position: absolute; 
                    top: 50%; 
                    left: -5px;
                    transform: translateY(-50%);
                    width: 2px; 
                    height: 30px;
                    background-color: rgb(188, 188, 188);
                }
                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: $black-color;
                }
            }
            .bixol-mobile-hamburger {
                display: none;
                margin-left: 15px;
                width: 45px;
            }
        }
    }
    @include large-screen {
        .header-navigation {
            .header-right {
                .bixol-mobile-hamburger {
                    display: inline-block;
                }
            }
        }
    }
    @include medium-screen {
        .home7-infobar {
            display: none;
        }
        .header-navigation {
            .header-right {
                .bixol-mobile-hamburger {
                    width: 35px;
                }
            }
        }
    }
    @include portrait-phone {
        .header-navigation {
            .header-right {
                .header-search {
                    display: none;
                }
                .ct-cart-btn {
                    display: none;
                }
            }
        }
    }
}

/********************* Sticky Header *********************/ 
.header-sticky {
    position: fixed; 
    width: 100%; 
    left: 0; 
    top: 0; 
    z-index: 50;
    animation: 0.3s slideInDown linear;
    box-shadow: $box-shadow;
    &.bixol-header {
        .bixol-info-bar {
            display: none;
        }
        .sticky-info {
            display: none;
        }
        .bixol-header-top {
            display: none;
        }
        .bixol-navigation {
            .bixol-nav {
                .bixol-header-logo {
                    width: 100px;
                    top: 0; 
                    transform: translateY(0);
                    background-color: $white-color; 
                    border-radius: 0 0 50% 50%;
                }
            }
        }
    }
    &.bg-sticky-white {
        background-color: $white-color;
    }
}




/********** Header Search Form ************/ 
.h4-header-search-form {
    position: fixed; 
    width: 100%; 
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 10;
    top: 0; 
    left: 0;
    display: flex; 
    align-items: center; 
    justify-content: center;
    transform: scale(0.7);
    visibility: hidden; 
    opacity: 0;
    transition: $transition;
    a.form-close {
        position: absolute; 
        top: 60px; 
        right: 60px;
        font-size: 40px;
        color: $white-color;
    }
    form {
        min-width: 400px;
        position: relative;
        input[type="text"] {
            width: 100%; 
            padding: 10px 18px;
            border: 0;
            font-size: 14px;
            border-radius: 3px;
        }
        button {
            position: absolute; 
            top: 0; 
            right: 0; 
            width: 52px; 
            height: 42px;
            border-radius: 0 3px 3px 0;
            display: inline-block;
            border: 0;
            background-color: $home4-secondary;
            color: $white-color;
            font-family: $heading-font; 
            font-weight: 700;
        }
    }
}
.search-form-open {
    transform: scale(1);
    opacity: 1; 
    visibility: visible;
    form {
        animation: 1s ct_fadeInUp;
    }
}


