/************* Blog Page ****************/ 
.blog-content-section {
    background-color: #eceef4;
    position: relative;
    z-index: 1;
    .star-object {
        position: absolute; 
        top: 200px; 
        left: 100px;
        z-index: -1;
    }
    .blog-posts {
        padding-right: 20px;
        .blog-single-post {
            display: flex;
            align-items: center; 
            gap: 20px;
            box-shadow: $box-shadow;
            + .blog-single-post {
                margin-top: 60px;
            }
            .bixol-img-wrapper {
                position: relative;
                img {
                    max-width: 270px;
                    width: auto;
                }
                .bixol-blog-date {
                    width: 65px; 
                    height: 65px; 
                    border-radius: 3px; 
                    background-color: $primary-color; 
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    padding: 0 10px;
                    text-align: center;
                    position: absolute;
                    top: 10px; 
                    left: 10px;
                    span {
                        color: $white-color;
                        font-family: $heading-font; 
                        font-weight: 700; 
                        font-size: 20px;
                    }
                }
            }
            .bixol-blog-content {
                padding-right: 20px;
                .bixol-category {
                    display: inline-block; 
                    padding: 3px 6px;
                    background-color: #ced4e6; 
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $heading-color;
                }
                .bixol-blog-headline {
                    margin-top: 20px;
                    h5 {
                        text-transform: initial;
                        position: relative; 
                        display: inline-block;
                        &::after {
                            content: ''; 
                            position: absolute; 
                            bottom: -4px; 
                            left: 50%; 
                            transform: translateX(-50%); 
                            width: 0; 
                            height: 2px; 
                            background-color: $heading-color;
                            transition: $transition;
                        }
                    }
                }
                .bixol-blog-pera {
                    margin-top: 20px;
                }
                .bixol-blog-btn {
                    a {
                        margin-top: 30px;
                    }
                }
            }
            &:hover {
                .bixol-blog-content {
                    .bixol-blog-headline {
                        h5 {
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .bixol-pagination {
        margin-top: 30px;
        ul {
            li {
                display: inline-block;
                a {
                    width: 35px; 
                    height: 35px; 
                    background-color: $white-color; 
                    display: inline-block; 
                    text-align: center; 
                    line-height: 35px; 
                    font-size: 15px; 
                    font-weight: 700; 
                    border-radius: 2px;
                    color: $primary-color;
                    transition: $transition; 
                    margin-right: 5px;
                    &:hover {
                        background-color: $primary-color; 
                        color: $white-color;
                    }
                    &.active {
                        background-color: $primary-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
    .blog-sidebar {
        .sidebar-widget {
            position: relative; 
            &::after {
                content: ''; 
                position: absolute; 
                left: 0; 
                top: 0; 
                width: 100%; 
                height: 6px; 
                background-color: $secondary-color;
            }
            .widget-title {
                padding: 30px 0;
                h5 {
                    position: relative; 
                    display: inline-block; 
                    &::after {
                        content: ''; 
                        position: absolute; 
                        top: 50%; 
                        right: -50px; 
                        transform: translateY(-50%); 
                        width: 40px; 
                        height: 3px; 
                        background-color: $secondary-color;
                    }
                }
            }
            &.no-border {
                &::after {
                    display: none;
                }
            }
            + .sidebar-widget {
                margin-top: 60px;
            }
        }
        .author-widget {
            position: relative; 
            border-radius: 3px;
            z-index: 1;
            text-align: center;
            padding: 40px 0;
            background-size: cover; 
            background-repeat: no-repeat;
            &::before {
                content: ''; 
                width: 100%; 
                height: 100%; 
                left: 0; 
                top: 0; 
                position: absolute; 
                background-color: rgba(0, 21, 85, 0.6);
                z-index: -1;
                border-radius: 3px;
            }
            .dot-1 {
                position: absolute;
                left: -10px;
                top: 10px;
            }
            .dot-2 {
                position: absolute; 
                bottom: 10px; 
                right: -10px;
                display: inline-block;
                span {
                    width: 20px; 
                    height: 20px;
                    display: block;
                    background-color: $primary-color;
                    + span {
                        margin-top: 4px;
                    }
                    &:nth-child(1) {
                        background-color: $secondary-color;
                    }
                    &:nth-child(2) {
                        background-color: #4a74f0; 
                    }
                }
            }
            .author-thumb {
                width: 140px;
                height: 140px; 
                border-radius: 50%;
                display: inline-block;
                overflow: hidden;
                img {
                    transform: scale(1.1);
                    background-color: $white-color; 
                    padding: 4px;
                    border-radius: 50%;
                }
            }
            .author-info {
                h5 {
                    margin-top: 15px;
                    color: $white-color;
                }
                span {
                    color: #d7e0f5;
                    font-size: 14px;
                }
            }
            .social-info {
                margin-top: 20px;
                a {
                    width: 40px; 
                    height: 40px;
                    background-color: $white-color;
                    color: $primary-color;
                    display: inline-block;
                    border-radius: 50%;
                    display: inline-flex; 
                    align-items: center; 
                    justify-content: center;
                    transition: $transition;
                    &.fb {
                        color: $facebook;
                    }
                    &.tw {
                        color: $twitter;
                    }
                    &.db {
                        color: $dribbble;
                    }
                    + a {
                        margin-left: 4px;
                    }
                    &:hover {
                        transform: translateY(-6px);
                    }
                }
            }
            
        }
        .search-sidebar {
            form {
                width: 100%; 
                position: relative;
                overflow: hidden;
                input[type="text"] {
                    width: 100%; 
                    padding: 12px 60px 12px 20px;
                    border: 0; 
                    background-color: $white-color; 
                    border-radius: 3px;
                    color: $heading-color; 
                    font-size: 15px; 
                    font-family: $heading-font;
                    font-weight: 600; 
                    &::placeholder {
                        color: $heading-color;
                    }
                }
                button {
                    position: absolute; 
                    right: 0; 
                    top: 0; 
                    border: 0; 
                    width: 50px; 
                    height: 46px;
                    background-color: $primary-color;
                    color: $white-color;
                    display: inline-flex; 
                    align-items: center; 
                    justify-content: center;
                    border-radius: 0 3px 3px 0;
                }
            }
        }
        .category-widget {
            .list-nav {
                background-color: $white-color;
                ul {
                    li {
                        + li {
                            a {
                                border-top: 1px solid $border-color;
                                margin-top: 1px;
                            }
                        }
                        a {
                            color: $primary-color;
                            display: block; 
                            padding: 14px 16px;
                            font-weight: 600;
                            font-size: 15px;
                            border-radius: 0;
                            transition: $transition;
                            position: relative;
                            &::after {
                                content: '\f105';
                                font-family: 'Font Awesome 5 Free';
                                font-weight: 900; 
                                position: absolute; 
                                right: 0px;
                                top: 15px;
                                opacity: 0;
                                transition: $transition;
                            }
                            &.active {
                                background-color: $primary-color; 
                                color: $white-color;
                                &::after {
                                    right: 10px;
                                    opacity: 1;
                                }
                                
                            }
                            &:hover {
                                background-color: $primary-color;
                                color: $white-color;
                                &::after {
                                    right: 10px;
                                    opacity: 1;
                                }
                            }
                        }
                        &:first-child {
                            a {
                                border-radius: 5px 5px 0 0;
                            }
                        }
                        &:last-child {
                            a {
                                border-radius: 0 0 5px 5px;
                            }
                        }
                    }
                }
            }
        }
        .recent-post-widget {
            .recent-post-single {
                display: flex;
                + .recent-post-single {
                    margin-top: 50px;
                    position: relative; 
                    &::before {
                        content: ''; 
                        position: absolute; 
                        top: -25px; 
                        left: 0; 
                        width: 100%; 
                        height: 1px; 
                        background-color: #d5dde5;;
                    }
                }
                .post-thumbnail {
                    img {
                        width: 60px;
                        border-radius: 50%;

                    }
                }
                .recent-post-content {
                    margin-left: 20px;
                    .title {
                        h6 {
                            text-transform: initial;
                            transition: $transition;
                            font-size: 15px;
                        }
                    }
                    .blog-meta {
                        margin-top: 6px;
                        span {
                            color: #3a4268;
                        }
                    }
                }
                &:hover {
                    .recent-post-content {
                        .title {
                            h6 {
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
        .tags-widget {
            .tags-list {
                a {
                    min-width: 83px; 
                    height: 40px; 
                    padding: 0 10px;
                    display: inline-block;
                    background-color: $white-color; 
                    color: $heading-color; 
                    text-align: center; 
                    line-height: 40px;
                    font-weight: 500; 
                    font-size: 13px;
                    margin-right: 6px;
                    margin-bottom: 10px;
                    transition: $transition; 
                    &:hover {
                        background-color: $primary-color; 
                        color: $white-color;
                    }
                }
            }
        }
        .instagram-widget {
            ul {
                li {
                    position: relative;
                    padding-right: 10px; 
                    padding-bottom: 10px;
                    display: inline-block;
                    a {
                        position: absolute;
                        top: 0; 
                        left: 0;
                        width: calc(100% - 10px);
                        height: calc(100% - 10px);
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        color: $white-color; 
                        background-color: rgba(0,0,0,0.7);
                        opacity: 0;
                        transition: $transition;
                    }
                    &:hover {
                        a {
                            opacity: 1;
                        }
                    }
                }
            }
            @include medium-screen {
                max-width: 250px;
            }
        }
    }

}
