#ct-loadding {
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  transition: all 300ms linear 0ms;
  background-color: #fff;
  transform: scale(1);
}

.loading-infinity {
  width: 120px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  -khtml-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.loading-infinity div {
  top: 0;
  left: 50%;
  width: 70px;
  height: 70px;
  animation: ctLoadingrotate 6.9s linear infinite;
  position: absolute;
}

.loading-infinity div span {
  left: -10px;
  top: 50%;
  margin: -10px 0 0;
  width: 20px;
  height: 20px;
  display: block;
  background: #007cfb;
  box-shadow: 2px 2px 8px rgba(0,124,251,9%);
  border-radius: 50%;
  transform: rotate(90deg);
  animation: ctLoadingmove 6.9s linear infinite;
  position: absolute;
}

.loading-infinity div span:before {
  animation: ctLoadingdrop1 .8s linear infinite;
}

.loading-infinity div span:after {
  animation: ctLoadingdrop2 .8s linear infinite .4s;
}

.loading-infinity div span:before, .loading-infinity div span:after {
  content: '';
  position: absolute;
  display: block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: inherit;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  box-shadow: inherit;
}

.loading-infinity div:nth-child(2) {
  animation-delay: -2.3s;
}
.loading-infinity div:nth-child(2) span {
  animation-delay: -2.3s;
}

.loading-infinity div:nth-child(3) {
  animation-delay: -4.6s;
}

.loading-infinity div:nth-child(3) span {
  animation-delay: -4.6s;
}