.h5-service-area {
    .h4-service-column {
        display: flex; 
        gap: 20px; 
        align-items: center;
        padding: 20px;
        border-radius: 3px; 
        border: 1px solid $border-color;
        position: relative;
        transition: $transition;
        margin-bottom: 30px;
        &::after {
            content: ''; 
            position: absolute; 
            bottom: 0; 
            right: 0; 
            width: 0; 
            height: 3px; 
            background-color: $home5-primary;
            transition: $transition;
        }
        .icon-wrapper {
            span {
                width: 90px; 
                height: 90px; 
                background-color: #f0f1f3; 
                display: inline-flex; 
                align-items: center; 
                justify-content: center;
                font-size: 40px; 
                border-radius: 50%;
                img {
                    width: auto;
                }
            }
        }
        .service-content {
            h5 {
                transition: $transition;
            }
            p {
                font-size: 15px;
                margin-top: 6px;
            }
        }
        &:hover {
            transform: translateY(-10px);
            box-shadow: $box-shadow;
            &::after {
                width: 100%; 
                left: 0; 
                right: auto;
            }
            .service-content {
                h5 {
                    color: $home5-primary;
                }
            }
        }
    }
}

/************** Home5 About Area *************/ 
.h5-about-area {
    .about-left {
        position: relative;
        .img-wrapper {
            display: inline-block;
            img {
                max-width: 100%; 
                width: auto;
            }
        }
        .yr-ex {
            position: absolute; 
            top: 40px; 
            right: 0; 
            display: flex; 
            gap: 10px; 
            background-color: $home5-primary; 
            box-shadow: -2.084px 11.818px 32px 0px rgba(12, 12, 12, 0.19);
            z-index: 2;
            padding: 15px 20px;
            border-radius: 3px;
            .icon-wrapper {
                span {
                    width: 60px; 
                    height: 60px; 
                    background-color: $white-color;
                    color: $home5-primary;
                    display: inline-block;
                    border-radius: 50%;
                    display: inline-flex; 
                    align-items: center; 
                    justify-content: center;
                    font-size: 30px;
                    transform: translateY(10px);
                }
            }
            .yr-content {
                .title {
                    font-size: 36px; 
                    font-weight: 700; 
                    font-family: $heading-font;
                    display: block;
                    color: $white-color;
                }
                .subtitle {
                    color: $white-color; 
                    font-size: 18px;
                    margin-top: -6px;
                    display: inline-block;
                    line-height: 1.1em;
                }
            }
        }
    }
    .about-right {
        padding-left: 20px;
        .about-content {
            margin-top: 15px;
            span {
                font-weight: 700;
            }
            .h4-pera-txt {
                margin-top: 15px;
            }
        }
        .about-btn {
            margin-top: 50px;
            
        }
    }
    @include medium-screen {
        .about-left {
            display: inline-block;
        }
        .about-right {
            margin-top: 60px;
        }
    }
}

/*********** Home5 Feature Section ************/ 
.h5-feature-section {
    background-color: #f3f4f8;
    height: 400px; 
    overflow: visible;
    .h5-service-slider {
        overflow: hidden;
        position: relative; 
        padding-bottom: 80px;
        z-index: 10;
        .h5-service-single {
            background-color: $white-color;
            border: 1px solid $border-color;
            border-radius: 3px; 
            overflow: hidden;
            transition: $transition;
            .service-content {
                padding: 30px;
                display: flex;
                gap: 15px;
                .icon-wrapper {
                    span {
                        color: $home5-primary; 
                        font-size: 36px;
                    }
                }
                .sr-details {
                    .h4-pera-txt {
                        margin-top: 6px;
                        font-size: 15px;
                    }
                    .readmore-btn {
                        margin-top: 15px;
                        a {
                            color: $home5-primary; 
                            font-weight: 700;
                            text-transform: initial;
                            transition: $transition;
                            span {
                                width: 20px; 
                                height: 20px;
                                background-color: $home5-primary;
                                display: inline-flex;
                                align-items: center; 
                                justify-content: center;
                                border-radius: 50%;
                                line-height: 20px; 
                                color: $white-color;
                                margin-right: 6px;
                                font-size: 12px;
                            }
                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
            &:hover {
                box-shadow: $box-shadow;
            }
        }
        .slick-list {
            margin: 0 -15px; 
            padding: 40px 0;
            overflow: visible;
            .slick-slide {
                margin: 0 15px;
                opacity: 0;
                visibility: hidden;
                &.slick-active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .slick-dots {
            position: absolute; 
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
            z-index: 10;
            li {
                display: inline-block;
                width: 15px; 
                height: 15px; 
                border: 2px solid transparent;
                padding: 0;
                border-radius: 50%;
                transition: $transition;
                + li {
                    margin-left: 6px;
                }
                button {
                    font-size: 0;
                    border: 0;
                    width: 100%; 
                    height: 100%;
                    background-color: #d4d4d4;
                    border-radius: 50%;
                    padding: 0;
                    display: block;
                    transition: $transition;
                }
                &.slick-active {
                    border: 2px solid $home5-primary;
                    padding: 2px;
                    button {
                        background-color: $home5-primary;
                    }
                }
            }
        }
    }
    @include small-screen {
        .h5-service-slider {
            max-width: 400px;
            margin-left: auto; 
            margin-right: auto;
        }
    }
    @include screen-460 {
        .h5-service-slider {
            .slick-dots {
                bottom: 80px;
            }
        }
    }
}

/********************* Home5 Git *****************/ 
.home5-git-area {
    position: relative; 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: -185px 0;
    z-index: 1;
    &::before {
        content: ''; 
        position: absolute; 
        top: 0;
        right: 0; 
        width: 55%;
        height: 100%;
        background-color: $black-color;
        clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0 100%);
        z-index: -1;
    }
    .git-content {
        padding-left: 60px;
        .title {
            color: $white-color; 
            font-size: 40px; 
            display: block;
            font-family: $heading-font; 
            font-weight: 700;
            line-height: 1.2em;
        }
        p {
            color: #c0bfbf;
            margin-top: 10px;
        }
        .home5-primary-btn {
            margin-top: 40px;
        }
    }
    @include medium-screen {
        &::before {
            width: 100%;
        }
    }
    @include small-screen {
        .git-content {
            padding-left: 0;
            text-align: center;
        }
    }
    
} 


/************ Home5 Feedback Area ****************/ 
.home5-feedback-area {
    .title-style-3 {
        padding-right: 20px;
    }
    .home5-feedback-slider {
        .feedback-single {
            padding: 20px; 
            border: 1px solid $border-color;
            transition: $transition;
            .feedback-bottom {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-top: 20px;
                .clients_thumb {
                    img {
                        border-radius: 50%;
                    }
                }
                .clients_info {
                    h6 {
                        font-size: 17px;
                        color: $home5-primary;
                    }
                    span {
                        color: $black-color; 
                        font-size: 14px;
                    }
                    .feedback-star {
                        span {
                            color: #ffc601;
                        }
                    }
                }
            }
            &:hover {
                box-shadow: 0px 27px 32px 0px rgba(12, 12, 12, 0.16);
                transform: translateY(-10px);
            }
        }
        .slick-list {
            margin: 0 -15px;
            padding: 40px 0;
            overflow: visible;
            .slick-slide {
                margin: 0 15px;
                opacity: 0; 
                visibility: hidden;
                &.slick-active {
                    opacity: 1; 
                    visibility: visible;
                }
            }
        }
        .slick-arrow {
            position: absolute; 
            top: -95px;
            right: 0;
            width: 60px; 
            height: 60px; 
            background-color: transparent;
            border-radius: 50%;
            border: 2px solid $home5-primary;
            color: $home5-primary;
            font-size: 20px;
            transition: $transition; 
            &.prev-btn {
                right: 80px;
            }
            &:hover {
                color: $white-color;
                background-color: $home5-primary;
            }
        }
    }
    @include medium-screen {
        .home5-feedback-slider {
            margin-top: 60px;
            .slick-list {
                padding: 60px 0;
            }
            .slick-arrow {
                top: -30px;
                right: auto; 
                left: calc(50%);
                transform: translateX(-86%);
                z-index: 10;
                &.next-btn {
                    left: calc(50% + 75px);
                }
            }
        }
    }
    @include small-screen {
        .title-style-3 {
            text-align: center;
        }
        .home5-feedback-slider {
            max-width: 400px; 
            margin-left: auto; 
            margin-right: auto;
        }
    }
        
}

/****************** Home5 Portfolio Area ***********************/ 
.home5-portfolio-area {
    background-color: $black-color;
    height: 400px;
    overflow: visible;
    .title-style-3 {
        .bixol-subtitle {
            color: $white-color;
        }
        h3 {
            color: $white-color;
        }
    }
    .bf-desc {
        padding-left: 20px;
        p {
            color: #d6d6d6;
        }
    }
    .home5-portfolio-slider {
        position: relative; 
        z-index: 10;
        .portfolio-single {
            transition: $transition;
            background-color: $white-color;
            .img-wrapper {
                position: relative; 
                border-radius: 4px 4px 0 0;
                overflow: hidden;
                .overlay {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.3);
                    position: absolute;
                    top: 0; 
                    left: 0;
                    display: flex; 
                    align-items: center; 
                    justify-content: center;
                    opacity: 0; 
                    visibility: hidden;
                    transition: $transition;
                    a {
                       width: 60px; 
                       height: 60px; 
                       background-color: $home5-primary;
                       display: inline-block; 
                       border-radius: 50%;
                       text-align: center; 
                       line-height: 60px;
                       color: $white-color;
                       transition: $transition; 
                       &:hover {
                           box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.32);
                       }
                    }
                }
            }
            .portfolio-bottom {
                padding: 20px;
                border: 1px solid $border-color;
                border-top: 0;
                h5 {
                    text-transform: capitalize;
                }
                .subtitle {
                    margin-top: 6px;
                    height: 22px;
                    overflow: hidden;
                    transition: $transition;
                    span {
                        color: $h5-peragraph;
                    }
                }
                .readmore-btn {
                    height: 0; 
                    overflow: hidden;
                    transition: $transition;
                    a {
                        color: $home5-primary; 
                        display: inline-block;
                        margin-top: 6px;
                        font-weight: 700;
                        span {
                            width: 25px; 
                            height: 25px;
                            background-color: $home5-primary;
                            display: inline-block;
                            border-radius: 50%;
                            color: $white-color;
                            font-size: 12px; 
                            display: inline-flex; 
                            align-items: center; 
                            justify-content: center;
                            margin-right: 6px;
                        }
                    }
                }
            }
            &:hover {
                box-shadow: 0px 15px 15px 0px rgba(12, 12, 12,0.16);
                transform: translateY(-10px);
                .img-wrapper {
                    .overlay {
                        opacity: 1; 
                        visibility: visible;
                        a {
                            animation: 0.5s fadeInDown;
                        }
                    }
                }
                .portfolio-bottom {
                    .subtitle {
                        height: 0;
                    }
                    .readmore-btn {
                        height: 40px;
                    }
                }
            }
        }
        .slick-list {
            margin: 0 -15px;
            padding: 40px 0;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-arrow {
            position: absolute; 
            top: -80px; 
            right: 0;
            width: 60px; 
            height: 60px; 
            padding: 0; 
            border-radius: 50%; 
            border: 0; 
            background-color: $home5-primary;
            color: $white-color;
            transition: $transition;
            &:hover {
                background-color: $home5-secondary;
            }
            &.prev-btn {
                right: 80px;
            }
        }
    }
    @include medium-screen {
        height: auto;
        padding-bottom: 60px;
        .title-style-3 {
            text-align: center;
        }
        .bf-desc {
            padding-left: 0;
            text-align: center; 
            margin-top: 20px;
        }
        .home5-portfolio-slider {
            margin-top: 100px;
            .slick-arrow {
                top: -60px;
                right: auto; 
                left: calc(50%);
                transform: translateX(-86%);
                z-index: 10;
                &.next-btn {
                    left: calc(50% + 75px);
                }
            }
        }

    }
    @include small-screen {
        .home5-portfolio-slider {
            max-width: 400px; 
            margin-left: auto; 
            margin-right: auto;
        }
    }
}

/*************** Home5 Blog Area *****************/ 
.h5-blog-area {
    background-color: #f3f4f8; 
    .h5-blog-slider {
        .h5-blog-single {
            transition: $transition;
            background-color: $white-color;
            .thumb-wrapper {
                position: relative; 
                overflow: hidden;
                border-radius: 5px 5px 0 0; 
                &::before {
                    content: ''; 
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%;
                    background-color: rgba(0,0,0,0.15);
                    transition: $transition;
                    z-index: 2;
                }
                &::after {
                    content: ''; 
                    position: absolute; 
                    left: -130px; 
                    top: 0; 
                    width: 100px; 
                    height: 100%;
                    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%); 
                    transform: skew(-10deg);
                }
            }
            .blog-content {
                padding: 25px;
                .blog-date {
                    width: 80px; 
                    height: 80px; 
                    background-color: $home5-primary;
                    color: $white-color;
                    display: flex; 
                    align-items: center;
                    justify-content: center; 
                    flex-direction: column;
                    margin-top: -80px;
                    position: relative; 
                    z-index: 2;
                    .title {
                        font-size: 30px;
                        font-family: $heading-font;
                        font-weight: 700; 
                    }
                    .subtitle {
                        margin-top: -6px;
                    }
                }
                .blog-meta {
                    margin-top: 20px;
                    span {
                        text-transform: uppercase; 
                        color: $home4-secondary; 
                        font-size: 13px;
                        color: #676f95;
                        font-weight: 500;
                        i {
                            margin-right: 6px;
                            color: $home5-primary;
                        }
                        + span {
                            margin-left: 20px;
                            position: relative; 
                        }
                    }
                }
                .h4-headline {
                    margin-top: 10px;
                    h4 {
                        transition: $transition; 
                        &:hover {
                            color: $home4-secondary;
                        }
                    }
                }
                .seperator {
                    display: block; 
                    width: 100%; 
                    height: 2px; 
                    background-color:  rgba(25, 25, 25, 0.09);
                    margin-top: 12px; 
                    margin-bottom: 15px;
                    position: relative; 
                    &::before {
                        content: ''; 
                        position: absolute; 
                        bottom: 0; 
                        left: 0; 
                        width: 45px; 
                        height: 4px; 
                        background-color: $home5-primary;
                    }
                }
                .readmore-btn {
                    a {
                        margin-top: 15px;
                        display: inline-block;
                        text-transform: uppercase; 
                        font-weight: 700;
                        text-decoration: underline;
                        color: $black-color;
                        transition: $transition;
                        span {
                            margin-left: 3px;
                        }
                    }
                }
            }
            &:hover {
                box-shadow: 0px 18px 16px 0px rgba(12, 12, 12,0.16);
                transform: translateY(-15px);
                .thumb-wrapper {
                    &::before {
                        opacity: 0;
                        visibility: hidden;
                    }
                    &::after {
                        animation: 800ms shine;
                    }
                }
                .blog-content {
                    .readmore-btn {
                        a {
                            color: $home5-primary;
                        }
                    }
                }
            }
        }
        .slick-list {
            padding: 40px 0;
            margin: 0 -15px;
            .slick-slide {
                margin: 0 15px;
            }
        }
        .slick-arrow {
            position: absolute; 
            top: -80px; 
            right: 0; 
            width: 60px; 
            height: 60px; 
            border: 0; 
            border-radius: 50%;
            background-color: #494949; 
            color: $white-color; 
            transition: $transition; 
            &.prev-btn {
                right:80px;
            }
            &:hover {
                background-color: $home5-primary;
            }
        }
    }
    @include medium-screen {
        .title-style-3 {
            text-align: center;
        }
        .bf-desc {
            text-align: center; 
            margin-top: 20px;
        }
        .h5-blog-slider {
            margin-top: 100px;
            .slick-arrow {
                top: -60px;
                right: auto; 
                left: calc(50%);
                transform: translateX(-86%);
                z-index: 10;
                &.next-btn {
                    left: calc(50% + 75px);
                }
            }
        }
    }
    @include small-screen {
        .h5-blog-slider {
            max-width: 500px; 
            margin-left: auto; 
            margin-right: auto;
        }
    }
}







