/* Navbar */
/**********************Desktop Menu*************
************************************************/
.bixol-navigation {
    .desktop-menu {
        padding: 0 40px;
        ul {
            text-align: right;
            li {
                display: inline-block;
                position: relative;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    float: left;
                }
                + li {
                    margin-left: 25px;
                }
                a {
                    padding: 18px 25px;
                    display: inline-block;
                    color: $white-color;
                    text-decoration: none; 
                    font-family: $heading-font; 
                    font-weight: 600;
                    text-transform: capitalize;
                    position: relative; 
                    &::before {
                        content: ''; 
                        position: absolute; 
                        top: 50%; 
                        left: 0px; 
                        transform: translateY(-50%); 
                        width: 25px; 
                        height: 3px; 
                        background-color: $secondary-color;
                        opacity: 0;
                        transition: $transition;
                    }
                    &:hover {
                        &::before {
                            opacity: 1; 
                            left: -10px;
                        }
                    }
                }
                //Sumenu 
                &.has-submenu {
                    & > a {
                        &::after {
                            content: '+'; 
                            position: absolute; 
                            top: 50%; 
                            right: 13px; 
                            transform: translateY(-50%);
                            transition: $transition;
                        }
                    }
                    ul {
                        position: absolute; 
                        top: calc(100% + 10px); 
                        left: 0; 
                        min-width: 220px; 
                        background-color: $white-color;
                        box-shadow: $box-shadow;
                        text-align: left;
                        border-radius: 0 0 10px 10px;
                        opacity: 0; 
                        visibility: hidden;
                        transition: $transition;
                        z-index: 20;
                        li {
                            margin-left: 0;
                            display: block;
                            float: none;
                            a {
                                color: $paragraph-color;
                                padding: 10px 25px;
                                font-weight: 500;
                                display: block;
                                transition: $transition;
                                &::before {
                                    width: 10px;
                                    left: 15px;
                                }
                                &:hover {
                                    padding-left: 30px;
                                }
                            }
                        }

                    }
                    &:hover {
                        & > a {
                            &::after {
                                transform: translateY(-50%) rotate(45deg);
                            }
                        }
                        & > ul {
                            top: 100%;
                            opacity: 1; 
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}


/********************* Mobile Menu ********************/ 
.bixol-mobile-menu {
    position: fixed; 
    width: 220px;
    height: 100vh;
    top: 0; 
    left: -250px;
    background-color: $white-color;
    padding-top: 30px;
    z-index: 99;
    transition: $transition;
    box-shadow: $box-shadow;
    overflow-y: scroll;
    .mobile-menu-logo {
        width: 130px;
        display: block;
        margin: 0 auto;
    }
    ul {
        margin-top: 20px;
        li {
            display: block; 
            a {
                display: block;
                padding: 8px 8px 8px 15px; 
                border-bottom: 1px solid $border-color;
                font-family: $heading-font; 
                font-weight: 500;
                color: $heading-color;
            }
            &.has-submenu {
                position: relative;
                & > a {
                    position: relative; 
                    &::after {
                        content: '+';
                        position: absolute; 
                        top: 6px;
                        right: 10px;
                        background-color: $border-color;
                        width: 25px;
                        height: 25px; 
                        text-align: center; 
                        line-height: 25px;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                }
                ul {
                    margin-top: 0;
                    display: none;
                    li {
                        a {
                            padding-left: 25px;
                        }
                        &.has-submenu {
                            ul {
                                li {
                                    a {
                                        padding-left: 35px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu-active {
    left: 0; 
}



/******************* Header 2 Menu ******************/ 
.header-style-2 {
    .desktop-menu-wrapper {
        @include medium-screen {
            display: none;
        }
    }
    .desktop-menu {
        padding-left: 30px;
        ul {
            li {
                display: inline-block;
                position: relative;
                a {
                    padding: 18px 15px;
                    display: inline-block;
                    color: $white-color;
                    text-decoration: none; 
                    font-family: $heading-font; 
                    font-weight: 600;
                    text-transform: capitalize;
                    position: relative; 
                    transition: $transition;
                    &::before {
                        content: ''; 
                        position: absolute; 
                        bottom: -6px; 
                        left: 50%; 
                        transform: translateX(-50%) scale(0); 
                        width: 12px; 
                        height: 12px; 
                        border-radius: 50%; 
                        background-color: $secondary-color; 
                        border: 3px solid $primary-color;
                        transition: $transition;
                    }
                    &:hover {
                        &::before {
                            transform: translateX(-50%) scale(1);
                        }
                    }
                }
                //Sumenu 
                &.has-submenu {
                    & > a {
                        &::after {
                            content: '+'; 
                            position: absolute; 
                            top: 50%; 
                            right: 13px; 
                            transform: translateY(-50%);
                            transition: $transition;
                        }
                    }
                    ul {
                        position: absolute; 
                        top: calc(100% + 10px); 
                        left: 0; 
                        min-width: 220px; 
                        background-color: $white-color;
                        box-shadow: $box-shadow;
                        text-align: left;
                        border-radius: 0 0 10px 10px;
                        opacity: 0; 
                        visibility: hidden;
                        transition: $transition;
                        z-index: 20;
                        li {
                            margin-left: 0;
                            display: block;
                            a {
                                color: $paragraph-color;
                                padding: 10px 25px;
                                font-weight: 500;
                                display: block;
                                transition: $transition;
                                &::before {
                                    top: 16px; 
                                    left: 16px; 
                                }
                                &:hover {
                                    padding-left: 30px;
                                }
                            }
                        }

                    }
                    &:hover {
                        & > a {
                            &::after {
                                transform: translateY(-50%) rotate(45deg);
                            }
                        }
                        & > ul {
                            top: 100%;
                            opacity: 1; 
                            visibility: visible;
                        }
                    }
                }
                &:hover {
                    a {
                        color: $heading-color;
                    }
                }
            }
        }
        @include large-screen {
            display: none;
        }
    }
}

/**************** Header 3 Menu ******************/ 
.header-style-3 {
    .desktop-menu {
        ul {
            li {
                a {
                    color: $home3-primary;
                    &::before {
                        bottom: 0; 
                        left: 50%; 
                        border: 0;
                        width: 0;
                        height: 3px;
                        transform: translateX(-50%);
                        border-radius: 0;
                        background-color: $home3-primary;
                    }
                }
                ul {
                    li {
                        a {
                            &::before {
                                width: 0px;
                                top: 20px !important;
                            }
                            &:hover {
                                &::before {
                                    width: 10px;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    & > a {
                        &::before {
                            width: 60px;
                        }
                    }
                }
            }
        }
    }
}

/******************** Header 4 Menu ************************/ 
.header-style-4 {
    .desktop-menu {
        padding-left: 0;
        ul {
            li {
                a {
                    color: $black-color;
                    &::before {
                        bottom: 0; 
                        left: 50%; 
                        border: 0;
                        width: 0;
                        height: 3px;
                        transform: translateX(-50%);
                        border-radius: 0;
                        background-color: $home4-primary;
                    }
                }
                ul {
                    li {
                        a {
                            &::before {
                                width: 0px;
                                top: 20px !important;
                            }
                            &:hover {
                                &::before {
                                    width: 10px;
                                }
                            }
                        }
                    }
                }
                &.has-submenu {
                    & > a {
                        &::after {
                            color: $home4-primary;
                        }
                    }
                }
                &:hover {
                   & > a {
                        &::before {
                            width: 60px;
                        }
                    }
                }
            }
        }
    }
}


/************************* Header Style 5 *********************/ 
.header-style-5 {
    .header-navigation {
        .desktop-menu {
            ul {
                li {
                    a {
                        padding: 36px 25px;
                        &::before {
                            background-color: $home5-primary;
                        }
                    }
                    &:hover {
                        & > a {
                            color: $home5-primary;
                        }
                    }
                }
            }
        }
    }
}

/**************** Header Style 6 ****************/ 
.header-style-6 {
    .desktop-menu {
        ul {
            li {
                a {
                    color: $white-color;
                    padding: 23px 25px;
                }
                &:hover {
                    a {
                        color: $home6-secondary;
                        &::before {
                            background-color: $home6-secondary;
                        }
                        &::after {
                            color: $home6-secondary;
                        }
                    }
                }
                &.has-submenu {
                    a {
                        &::after {
                            color: $white-color;
                        }
                    }
                    &:hover {
                        a {
                            &::after {
                                color: $home6-secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}